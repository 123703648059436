import { createAsyncThunk, createSlice, PayloadAction, Reducer, } from "@reduxjs/toolkit"
import { PerspectiveCamera, Vector3 } from "three";

export interface Progress {
  loaded: number,
  total: number,
}

export interface LoaderState extends Progress {
  isCached: boolean,
  isLoaded: boolean,
}


const initialState: LoaderState = {
  isCached: false,
  isLoaded: false,
  loaded: 0,
  total: 0,
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    cacheUpdated: ( state, action: PayloadAction<boolean> ) => {
      state.isCached = action.payload;
    },
    progressUpdated: ( state, action: PayloadAction<Progress> ) => {
      state.loaded = action.payload.loaded;
      state.total = action.payload.total;

      if( state.loaded === state.total ) {
        state.isLoaded = true;
      }
    },
  },
} );

const { actions, reducer } = loaderSlice;

export const {
  cacheUpdated,
  progressUpdated,
} = actions;
export default reducer;
