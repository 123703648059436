import { createListenerMiddleware } from "@reduxjs/toolkit";
import { currentLocaleUpdated, titleUpdated, } from "../slices/app";
import { setLocale } from "../../locales/locales";
import { title } from "process";
import { msg, updateWhenLocaleChanges,  } from "@lit/localize";
import { RootState } from "../store";
// import { } from "@lit/localize";

const localeListenerMiddleware = createListenerMiddleware();

localeListenerMiddleware.startListening({
  actionCreator: currentLocaleUpdated,
  effect: async ( action, listenerApi) => {

    setLocale( action.payload ).then( () => {
      const state = listenerApi.getState() as RootState;
      listenerApi.dispatch( titleUpdated( state.app.title ) )
      document.documentElement.lang = action.payload;
    });
  }
} )

localeListenerMiddleware.startListening({
  actionCreator: titleUpdated,
  effect: async ( action, listenerApi) => {

    let title = ''
    switch( action.payload ) {
      case 'About Pino': {
        title = msg( 'About Pino', { id: 'title-about' } );
        break;
      }
      case 'Contact': {
        title = msg( 'Contact', { id: 'title-contact' } );
        break;
      }
      case 'Explore': {
        title = msg( 'Explore', { id: 'title-explore' } );
        break;
      }
      case 'Gallery': {
        title = msg( 'Gallery', { id: 'title-gallery' } );
        break;
      }
      case "Imprint": {
        title = msg( 'Imprint', { id: 'title-imprint' } );
        break;
      }
      case "Privacy Policy": {
        title = msg( 'Privacy Policy', { id: 'title-privacy' } );
        break;
      }
      case "Making of": {
        title = msg( 'Making of', { id: 'title-making-of' } );
        break;
      }
      case "Install": {
        title = msg( 'Install', { id: 'title-install' } );
        break;
      }
      default: {
        title = '';
      }
    }

    // console.log('doing it', title );

    document.title = `${ title ? `${ title } | ` : '' }Pino Art`;
  }
} )

export default localeListenerMiddleware.middleware;
