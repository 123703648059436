import { html } from "lit";

export default html`
<style>
  :host, :root {
    --pino-main-contrast: white;

    --pino-main-darker: hsl(207, 50%, 9%);
    --pino-main-dark: hsl(208, 39%, 12%);
    --pino-main: hsl(205, 33%, 16%);
    --pino-main-light: hsl(203, 23%, 20%);
    --pino-main-lighter: hsl(203, 18%, 26%);

    --pino-primary: hsl(194, 100%, 50%);

    /* Card */
    --pino-card-background: rgba( 0, 0, 0, .9 );

    /* Header */
    --pino-header-background: var( --pino-main-dark );

    /* Rough */
    --pino-rough: var( --pino-main-contrast );
    background: black;
  }
</style>
`;
