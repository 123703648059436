
import { createAction, createListenerMiddleware } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { feedbackClosed, nextFeedback, feedbackPushed } from "../slices/feedback";

export const feedbackDiscarded = createAction( 'app/feedbackDiscarded' );

const feedbackListenerMiddleware = createListenerMiddleware();

let timeoutId = 0;
const time = 6000;

// Push

feedbackListenerMiddleware.startListening({
  actionCreator: feedbackPushed,
  effect: async ( action, listenerApi) => {

    const state = listenerApi.getState() as RootState;
    if( state.feedback.open ) return;

    listenerApi.dispatch( nextFeedback() );
  }
} );

// Close
//
feedbackListenerMiddleware.startListening({
  actionCreator: feedbackClosed,
  effect: async ( action, listenerApi) => {

    const state = listenerApi.getState() as RootState;
    if( timeoutId ) window.clearTimeout( timeoutId );
    if( state.feedback.queue.length ) {
      window.setTimeout( () => {
        listenerApi.dispatch( nextFeedback() );
      }, 200 );
    }

  }
} );

// Next

feedbackListenerMiddleware.startListening({
  actionCreator: nextFeedback,
  effect: async ( action, listenerApi) => {


    timeoutId = window.setTimeout( () => {
      listenerApi.dispatch( feedbackClosed() );
    }, time );
  }
} );

export default feedbackListenerMiddleware.middleware;
