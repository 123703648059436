import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
type Icon = 'info' | 'plugs' | 'refresh' | 'heart';

interface Feedback {
  message: string,
  path: string,
  action: string,
  actionType: 'link' | 'refresh',
  icon: Icon,
}
interface FeedbackOptions {
  message: string,
  path?: string,
  action?: string,
  actionType?: 'link' | 'refresh',
  icon?: Icon,
}

export interface FeedbackState extends Feedback {
  queue: Feedback[],
  open: boolean,
}

const initialState: FeedbackState = {
  queue: [],
  open: false,
  action: '',
  actionType: 'link',
  message: '',
  path: '',
  icon: 'info',
};

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    feedbackPushed: (state, payloadAction: PayloadAction<FeedbackOptions>) => {
      const { message, action, actionType, path, icon, } = payloadAction.payload;

      const feedback: Feedback = {
        message,
        action: action || '',
        icon: icon || 'info',
        path: path || '',
        actionType: actionType || 'link',
      }
      state.queue = [ ...state.queue, feedback ];
    },
    nextFeedback: ( state, ) => {
      const { action, actionType, message, path, icon, } = state.queue[0];
      state.action = action;
      state.message = message;
      state.path = path;
      state.icon = icon;
      state.actionType = actionType;
      state.queue = state.queue.slice( 1 );
      state.open = true;
    },
    feedbackClosed: (state) => {
      // mutate the state all you want with immer
      state.open = false;
    },
  },
} );

const { actions, reducer } = feedbackSlice;

export const {
  feedbackPushed,
  nextFeedback,
  feedbackClosed,
} = actions
export default reducer;
