import { createListenerMiddleware } from "@reduxjs/toolkit";
import { init, mobileUpdated } from "../slices/app";

const mobileListenerMiddleware = createListenerMiddleware();

const query = '(max-width: 700px)';

mobileListenerMiddleware.startListening({
  actionCreator: init,
  effect: async ( _, listenerApi) => {

    const mobile = window.matchMedia( query ).matches;

    listenerApi.dispatch( mobileUpdated( mobile ) );

    window.matchMedia( query ).addEventListener( 'change', event => {
      listenerApi.dispatch( mobileUpdated( event.matches ) );
    });
  }
} )


export default mobileListenerMiddleware.middleware;
