import {
  Group,
  Mesh,
  Points,
  PointsMaterial,
  TextureLoader,
} from "three";
import { defaultMaterial } from "../materials";
import { emmausModel } from "../resources";
import { Flock } from "./flock";
import { Model } from "./model";
import { Pino } from "./pino";

export class Emmaus extends Model {

  // private _truck = new Truck();
  private _flock = new Flock();
  public pino = new Pino();

  static model = emmausModel

  constructor() {
    super();

    Emmaus.model.then( ( emmaus ) => {


      const model = emmaus.clone();
      // this.add( this._truck );
      this.add( this._flock );
      this.add( this.pino );
      model.traverse( node => {

        const mesh = node as Mesh;

        mesh.castShadow = true;
        mesh.receiveShadow = true;
        mesh.material = defaultMaterial;
      })

      // model.children.forEach( child => {
      //   const mesh = child as Mesh;



      //   // mesh.material = materials[Math.floor(Math.random()*materials.length)];
      // })
      this.add( model );
      // const crown = model.getObjectByName( 'crown' ) as Mesh;
      // crown.visible = false;
      this.createParticles( model );

    })

  }



  createParticles( group: Group ) {
    // const position = mesh.geometry.attributes.position;


    // const suffixes = [ '', '000', '001', '002', '003', '004', '005', '006' ];

    // suffixes.forEach( suffix => {

    //   const crown = group.getObjectByName( `crown${ suffix }` ) as Mesh;

    //   if( crown ) {

    //     crown.visible = false;
    //     new TextureLoader().loadAsync( `/particles/tree-particles${ suffix }.png` ).then( texture => {

    //       const material = new PointsMaterial({ color: 0xffffff, map: texture, alphaTest: .9, size: Math.random()+2, transparent: true, } );

    //       const points = new Points( crown.geometry, material );
    //       points.position.set( crown.position.x, crown.position.y, crown.position.z );
    //       this.add( points );
    //     })
    //   }
    // })

  }


  tick() {
    // this._truck.tick();
    this._flock.tick();
    this.pino.tick();

  }

}
