import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store";

export interface UserState {
  login: string,
  id: number,
  avatar_url: string,
}

const initialState: UserState = {
  login: '',
  id: 0,
  avatar_url: '',
};


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userUpdated: (state, action: PayloadAction<UserState>) => {
      state.login = action.payload.login;
      state.id = action.payload.id;
      state.avatar_url = action.payload.avatar_url;
    },
  },
} );

export const fetchUser = createAsyncThunk(
  'fetch/user',
  async ( _, thunkAPI ) => {

    const state = thunkAPI.getState() as RootState;

    return fetch('/.netlify/functions/user', {
      method: 'POST',
      body: JSON.stringify( { token: state.auth.token, }),
      }).then( response => {
        if( !response.ok ) throw Error( 'No User.' );
        return response.json();

      }).then( data => {

        thunkAPI.dispatch( userUpdated({
          avatar_url: data.payload.avatar_url,
          id: data.payload.id,
          login: data.payload.login,
        } ) );

        return data;
      }).catch( error => {
        console.log( error );
      });

  }
)

const { actions, reducer } = userSlice;

export const { userUpdated } = actions
export default reducer;
