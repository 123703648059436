import {
  BufferAttribute,
  BufferGeometry,
  Float32BufferAttribute,
  MathUtils,
  Points,
  PointsMaterial,
  Sprite,
  SpriteMaterial,
  TextureLoader
} from "three";

import { Tween, Easing, } from '@tweenjs/tween.js';

import { Model } from "./model";
import { dustTexture } from "../resources";


export class Dust extends Model {

  private _playing = false;
  private _tween = new Tween( { value: 0, } );
  private _points!: Points;

  static texture = dustTexture;

  constructor() {
    super();



    Dust.texture.then( map => {

      // const sprite = new Sprite( new SpriteMaterial( { }))

      const vertices = [];

      for ( let i = 0; i < 20; i ++ ) {

        const x = Math.sin( i ) + MathUtils.randFloatSpread( .3 );
        const y = 0;
        const z = Math.cos( i ) + MathUtils.randFloatSpread( .3 );

        vertices.push( x, y, z );

      }

      const geometry = new BufferGeometry();
      geometry.setAttribute( 'position', new Float32BufferAttribute( vertices, 3 ) );

      const material = new PointsMaterial( { color: 0xffffff, size: 1,alphaTest: .1, map:map, transparent: true } );
      material.opacity = 0;
      this._points = new Points( geometry, material );
      this.add( this._points );

    })

  }

  play() {

    if( this._playing ) return;

    // this._playing = true;
    //
    let count = this._points.geometry.attributes.position.count;
    const material = this._points.material as PointsMaterial;

    this._tween.to( { value: 1, }, 1000 )
    .easing( 	Easing.Quadratic.Out )
    .onUpdate( ( { value } ) => {

      // if( !this._points ) return;


      const position = this._points.geometry.attributes.position as BufferAttribute;
      material.opacity = value < .5 ? 1 :Math.abs( value -1 ) * 2;
      material.size = 1 + ( value * .3 );

      let index = 0;
      for( index; index < count; index++ ) {
        if ( index < count * .5 ) {
          this._animateGroundDust( index, value, position );
        // } else if ( index < count * .6 ) {
        //   this._animateTopDust( index, value, position );
        } else {
          this._animateDust( index, value, position );
        }

      }
      // let index = 0;
      // for( index; index < count; index++ ) {

      //   let multiplier = 1;
      //   let startMultiplier = .2;

      //   if ( index > count / 2 && index % 2) {
      //     multiplier = .5;
      //     startMultiplier = .5;
      //   } else if( index > count / 2 ) {
      //     multiplier = .8;

      //   } else if( index % 4 ) {
      //     multiplier = 2;
      //   } else if ( index % 3 ) {
      //     multiplier = 3
      //   }
      //   const x = Math.sin( index )* ( value + startMultiplier )* multiplier
      //   // const x = Math.random()*20-10;
      //   // const z = Math.random()*20-10;
      //   const y = index > count / 2 ? value* multiplier * 2 : 0;
      //   const z = Math.cos( index )* ( value + startMultiplier)* multiplier

      //   position.setXYZ( index, x, y, z );
      // }
      position.needsUpdate = true;

      // for ( let i = 0; i < 100; i ++ ) {

      //   this._points[i].attributes.position[0]
      //   const x = Math.sin( i ) + MathUtils.randFloatSpread( .3 );
      //   const y = 0;
      //   const z = Math.cos( i ) + MathUtils.randFloatSpread( .3 );

      //   vertices.push( x, y, z );

      // }

    })
    .start();


  }

  _animateGroundDust( index: number, value: number, position: BufferAttribute ) {

    const x = Math.sin( index ) * ( value + .5);
    const z = Math.cos( index ) * ( value + .5);
    const y = 0;
    position.setW( index, index );

    position.setXYZ( index, x, y, z );

  }
  _animateTopDust( index: number, value: number, position: BufferAttribute ) {

  }
  _animateDust( index: number, value: number, position: BufferAttribute ) {

    const x = Math.sin( index ) * ( value + .5) * .5;
    const y = value * ( index % 5 * .7);
    const z = Math.cos( index ) * ( value + .5) * .5;
    position.setXYZ( index, x, y , z)
  }


  tick() {

    // this._tween.update();

  }

}
