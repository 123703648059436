import { createAction, createSlice, PayloadAction, } from "@reduxjs/toolkit"
import { allLocales } from "../../locales/generated/locale-codes";

export const init = createAction( 'app/init' );
export type ColorScheme = 'dark' | 'light';

export type Locale = typeof allLocales[number];
export type UserLocale = '' | Locale;

export type Title =
'' |
'Contact' |
'Privacy Policy' |
'Explore' |
'Gallery' |
'About Pino' |
'Making of' |
'Install' |
'Imprint';

export interface AppState {
  assets: string[]
  assetsFetched: boolean,
  colorScheme: ColorScheme,
  currentLocale: Locale,
  userLocale: UserLocale,
  isMobile: boolean,
  menu: boolean,
  title: Title,
  makingOf: boolean,
  // https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent
  installPrompt: boolean,
  // https://vite-pwa-org.netlify.app/guide/prompt-for-update.html#importing-virtual-modules
  needRefresh: boolean,
}

const initialState: AppState = {
  assets: [
    '/easel.glb',
  ],
  assetsFetched: false,
  colorScheme: 'light',
  isMobile: false,
  currentLocale: 'en-US',
  userLocale: '',
  menu: false,
  title: '',
  makingOf: false,
  installPrompt: false,
  needRefresh: false,
};

const authSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    mobileUpdated: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
    userLocaleUpdated: (state, action: PayloadAction<UserLocale>) => {
      state.userLocale = action.payload;
    },
    currentLocaleUpdated: (state, action: PayloadAction<Locale>) => {
      state.currentLocale = action.payload;
    },
    colorSchemeUpdated: (state, action: PayloadAction<ColorScheme>) => {
      state.colorScheme = action.payload;
    },
    titleUpdated: (state, action: PayloadAction<Title>) => {
      state.title = action.payload;
    },
    makingOfEnabled: (state) => {
      state.makingOf = true;
    },
    installPromptEnabled: (state) => {
      state.installPrompt = true;
    },
    refreshNeeded: (state) => {
      state.needRefresh = true;
    },
    menuUpdated: (state, action: PayloadAction<boolean>) => {
      state.menu = action.payload;
    },
  },
} );

const { actions, reducer } = authSlice;

export const {
  colorSchemeUpdated,
  mobileUpdated,
  currentLocaleUpdated,
  userLocaleUpdated,
  titleUpdated,
  makingOfEnabled,
  installPromptEnabled,
  refreshNeeded,
  menuUpdated,
} = actions;

export default reducer;
