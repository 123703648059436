import {
  Object3D,
  PerspectiveCamera,
  Vector3,
  AudioListener,
  AnimationMixer,
  Clock,
  AnimationAction,
  Mesh,
  LoopOnce,
  LoopPingPong,
} from "three";
import { Model } from "./model";

import { pinoModel, pinoPosition } from "../resources";

export type PinoState = 'drawing' | 'turning-around';

export class Pino extends Model {

  _mixer!: AnimationMixer;
  actions: {
    [ id:string ]: AnimationAction
  } = {};
  _clock = new Clock();

  private _pinoState: PinoState = 'drawing';

  get pinoState() {
    return this._pinoState;
  }
  set pinoState( pinoState: PinoState ) {

    this._pinoState = pinoState;

    if( pinoState === 'drawing' ) {
      this.draw();
    } else if( pinoState === 'turning-around' ) {
      this.turnAround();
    }
  }

  constructor( ) {
    super();


    Promise.all( [ pinoModel, pinoPosition ])
      .then( ([ gltf, positions ])=> {
        const pino = gltf.scene.getObjectByName( 'pino' ) as Mesh;
        this.add( gltf.scene );
        pino.castShadow = true;
        const pinoPosition = positions.getObjectByName( 'pino-position' );
        const pinoTarget = positions.getObjectByName( 'pino-target' );
        if( !pinoPosition || !pinoTarget ) throw Error( 'Positions not found!' )
        gltf.scene.position.set( pinoPosition.position.x, pinoPosition.position.y, pinoPosition.position.z  );
      gltf.scene.lookAt( pinoTarget.position );
        this._mixer = new AnimationMixer( gltf.scene );

        // this._mixer.addEventListener( 'loop', event => {
        //   console.log( 'loopfinised?', event )
        // })

        const focusing = gltf.animations.find( animation => animation.name === 'focusing' );
        if( !focusing ) throw Error( 'No animatino found!' );
        this.actions.focusing = this._mixer.clipAction( focusing );
        this.actions.focusing.play();

        this.actions.drawing = this._mixer.clipAction( gltf.animations.find( animation => animation.name === 'drawing'));
        this.actions.drawing.play();

        this.actions.turnAround = this._mixer.clipAction( gltf.animations.find( animation => animation.name === 'turn-around'));
        // this.actions.turnAround.play();
    })

  }

  public turnAround() {

    // this.actions.turnAround.crossFadeTo( this.actions.drawing, 2, false );
    // this.actions.drawing.crossFadeTo( this.actions.turnAround, 3, true );
    // this.actions.turnAround.play();

    this.actions.turnAround.loop = LoopOnce;
    this.actions.drawing.fadeOut( 2 );
    this.actions.focusing.fadeOut( 3 );

    this.actions.turnAround.play();
    this.actions.turnAround.repetitions = 1;
    this.actions.turnAround.clampWhenFinished = true;
    // setTimeout( () => {

    //   this.actions.drawing.stop();
    //   this.draw();
    // }, 3000 )
  }

  public draw() {
    // this.actions.turnAround.paused = false;
    // this.actions.turnAround.timeScale = -1;
    // this.actions.turnAround.setLoop( LoopOnce, 1 );
    // this.actions.turnAround.play();
    // this.actions.turnAround.fadeOut( .5 );
    // this.actions.turnAround.loop = LoopPingPong;
    // this.actions.turnAround.startAt( this.actions.turnAround.time  )
    // this.actions.turnAround.timeScale = -1;

    this.actions.turnAround.stop();
    this.actions.drawing.stop();
    this.actions.drawing.play();
    // this.actions.focusing.fadeIn( 3 );
  }


  tick() {
    this._mixer.update(this._clock.getDelta());

  }

}
