import { html, css, LitElement, PropertyValueMap } from 'lit'
import { property, query, customElement, } from 'lit/decorators.js'
import {createRef, Ref, ref} from 'lit/directives/ref.js';

import {
  ACESFilmicToneMapping,
  Clock,
  PCFShadowMap,
  PCFSoftShadowMap,
  PerspectiveCamera,
  Raycaster,
  Scene,
  SRGBColorSpace,
  Vector2,
  WebGLRenderer,
} from 'three';
import { ComponentElement } from '../elements/component-element';

/**
 * An example element.
 *
 * @slot - This element has a slot
 * @csspart button - The button
 */

@customElement('pino-gl')
export class PinoGL extends ComponentElement {

  public scene: Scene = new Scene();
  public camera: PerspectiveCamera = new PerspectiveCamera( 75, 1 );
  public renderer!: WebGLRenderer;
  public clock = new Clock();

  @query( 'canvas' ) canvas!: HTMLCanvasElement;
  canvasRef: Ref<HTMLCanvasElement> = createRef();

  @property( { type: Number, }) width = 300;
  @property( { type: Number, }) height = 300;

  resize = () => {

    // this.renderer.outputColorSpace = SRGBColorSpace;
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize( this.width, this.height );
    // this.renderer.toneMapping = ACESFilmicToneMapping;
    // this.renderer.toneMappingExposure = 1;
    // this.renderer.physicallyCorrectLights = true;

  }

  static styles = [
    super.styles,
    css`
    canvas {
      display: block;
    }
  ` ]

  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    this.renderer = new WebGLRenderer( {
      canvas: this.canvas,
      alpha: true,
      antialias: true,
    } );
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = PCFSoftShadowMap;
    this.renderer.outputColorSpace = SRGBColorSpace;
    // this.renderer.physicallyCorrectLights = true;
    this.resize();

  }

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if( _changedProperties.has( 'width' ) && this.width ) {

      this.resize();
    } else if( _changedProperties.has( 'height' ) && this.height ) {
      this.resize();
    }
  }

  tick() {

    const elapsedTime = this.clock.getElapsedTime();
  }

  render() {
    return html`
      <canvas
        ${ref(this.canvasRef)}
        width=${ this.width }
        height=${ this.height }
      ></canvas>
    `
  }

  disconnectedCallback(): void {
    console.log('ok removing webgl context')
    this.renderer.dispose()
    this.renderer.forceContextLoss()
    super.disconnectedCallback();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'pino-gl': PinoGL,
  }
}
