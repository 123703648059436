import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

interface Locales {
  "en": string,
}

export interface Exhibit {
  uuid: string,
  source: string,
  texture: string,
  thumbnail: string,
  colorIndex: number,
  created: string,
  modified: string,
  resolution: number,
  x: number,
  y: number,
  ratio: number,
  angle: number,
  typeIndex: number,
  size: number,
  title: Locales,
  description: Locales,
}

export interface ExhibitState {
  currentIndex: number,
  exhibits: Exhibit[],
}

const initialState: ExhibitState = {
  currentIndex: 0,
  exhibits: [],
};

export const fetchExhibits = createAsyncThunk(
  'fetch/exhibits',
  async ( _, thunkAPI ) => {

    return fetch('/exhibits.json', {
      method: 'GET',
      }).then( response => {

        if( !response.ok ) throw Error( 'Error loading exhibits.' );
        return response.json();

      }).then( data => {
        thunkAPI.dispatch( exhibitionAdded( data ))
        return data;
      })
      .catch( error => {
      })
  }
)

const exhibitionSlice = createSlice({
  name: 'exhibition',
  initialState ,
  reducers: {
    exhibitionAdded: (state, action: PayloadAction<Exhibit[]>) => {
      // state = [ ...state, ...action.payload ]
      state.exhibits.push( ...action.payload );
    },
    indexUpdated: (state, action: PayloadAction<number>) => {
      state.currentIndex = action.payload;
    },
    nextExhibit: (state) => {
      state.currentIndex = state.currentIndex + 1 < state.exhibits.length ? state.currentIndex + 1 : 0;
    },
    previousExhibit: (state) => {
      state.currentIndex = state.currentIndex === 0 ? state.exhibits.length - 1: state.currentIndex - 1;
    }
  },
} );

const { actions, reducer } = exhibitionSlice;

export const {
  exhibitionAdded,
  indexUpdated,
  nextExhibit,
  previousExhibit,
} = actions
export default reducer;
