import {
  Color,
  Mesh,
  Group,
  MeshStandardMaterial,
  LineSegments,
  Vector3,
  CatmullRomCurve3,
  SRGBColorSpace,
} from "three";

import { Model } from "./model";
import { Dust } from "./dust";
import { Tween, Easing, } from "@tweenjs/tween.js";
import { dustTexture, faceTexture, garbageModel, linkPositions, mailboxModel, safeModel, toiletModel, TruckModel, truckPath } from "../resources";
import { getPastelMaterial } from "../materials";

export class Links extends Model {

  private _tween = new Tween( { value: 0, } );
  private _curve!: CatmullRomCurve3;
  private _model!: Mesh;
  private _door!: Mesh;

  open() {

  }
  close() {

  }

  constructor( ) {
    super();

    Promise.all([ mailboxModel, toiletModel, garbageModel, safeModel, linkPositions, faceTexture ])
      .then( ( [ mailboxGltf, toiletGltf, garbageGltf, safeGltf, links, face ]) => {
        const faceMaterial = new MeshStandardMaterial( { color: 0xffffff, map: face })
        face.flipY = false;
        face.colorSpace = SRGBColorSpace;
        faceMaterial.needsUpdate = true;
        this.castShadow = true;
        this.receiveShadow = true;
        const mailboxPosition = links.scene.getObjectByName( 'mailbox' );
        if( mailboxPosition ) {
          mailboxGltf.children.forEach( child => {
            const mesh = child as Mesh;
            mesh.material = faceMaterial;
            mesh.castShadow = true;
            mesh.receiveShadow = true;
          })
          mailboxGltf.position.set( mailboxPosition.position.x, mailboxPosition.position.y, mailboxPosition.position.z );
          mailboxGltf.rotation.set( mailboxPosition.rotation.x, mailboxPosition.rotation.y, mailboxPosition.rotation.z );
          this.add( mailboxGltf );
        }
        const toiletPosition = links.scene.getObjectByName( 'toilet' );
        if( toiletPosition ) {
          toiletGltf.children.forEach( child => {
            const mesh = child as Mesh;
            mesh.material = faceMaterial;
            mesh.castShadow = true;
            mesh.receiveShadow = true;
          })
          toiletGltf.castShadow = true;
          toiletGltf.receiveShadow = true;
          toiletGltf.position.set( toiletPosition.position.x, toiletPosition.position.y, toiletPosition.position.z );
          toiletGltf.rotation.set( toiletPosition.rotation.x, toiletPosition.rotation.y, toiletPosition.rotation.z );
          this.add( toiletGltf );
        }
        const garbagePosition = links.scene.getObjectByName( 'garbage' );
        if( garbagePosition ) {
          garbageGltf.children.forEach( child => {
            const mesh = child as Mesh;
            mesh.material = faceMaterial;
            mesh.castShadow = true;
            mesh.receiveShadow = true;
          })
          garbageGltf.position.set( garbagePosition.position.x, garbagePosition.position.y, garbagePosition.position.z );
          garbageGltf.rotation.set( garbagePosition.rotation.x, garbagePosition.rotation.y, garbagePosition.rotation.z );
          this.add( garbageGltf );
        }
        const safePosition = links.scene.getObjectByName( 'safe' );
        if( safePosition ) {
          safeGltf.children.forEach( child => {
            const mesh = child as Mesh;
            mesh.material = faceMaterial;
            mesh.castShadow = true;
            mesh.receiveShadow = true;
          })
          safeGltf.position.set( safePosition.position.x, safePosition.position.y, safePosition.position.z );
          safeGltf.rotation.set( safePosition.rotation.x, safePosition.rotation.y, safePosition.rotation.z );
          this.add( safeGltf );
        }
    } )

  }


  tick() {
    // this._tween.update();
  }

}
