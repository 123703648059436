import {
  CanvasTexture,
  Color,
  Mesh,
  Group,
  MeshStandardMaterial,
  BufferGeometry,
  MathUtils,
  LinearFilter,
} from "three";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Model } from "./model";
import { Dust } from "./dust";
import { Tween, Easing, } from "@tweenjs/tween.js";
import { exhibitTypesModel } from "../resources";
import { getPastelMaterial } from "../materials";

interface ExhibitType {
  canvas: Mesh,

  frame?: Mesh,
  frameGeometry?: BufferGeometry,
  easel?: Mesh,
}

interface ExhibitOptions {
  size?: number,
  colorIndex?: number,
  typeIndex?: number,
  index?: number,
  x?: number,
  y?: number,
  source?: string,
  ratio?: number,
  angle?: number,
}

export class Exhibit extends Model {

  static model = exhibitTypesModel;

  private _canvas = document.createElement( 'canvas' );

  private _exhibitTypes: ExhibitType[] = [];
  private _currentCanvas!: Mesh;
  private _currentEasel: Mesh | null = null;
  private _currentFrame: Mesh | null = null;
  private _group = new Group();
  private _tween = new Tween( { value: 0, } );

  private _canvasMaterial = new MeshStandardMaterial({
    color: 0xffffff,
    metalness: 0,
    // map: new CanvasTexture( this._canvas ),
  });

  private _material = getPastelMaterial( 0 );

  private _dust = new Dust();

  private _colorIndex = 0;
  get colorIndex() { return this._colorIndex; }
  set colorIndex( colorIndex: number ) {
    this._colorIndex = colorIndex;
    this._material = getPastelMaterial( colorIndex );
  }
  public index = 0;

  private _typeIndex = 6;
  get typeIndex() { return this._typeIndex; }
  set typeIndex ( typeIndex ) {
    this._typeIndex = typeIndex;
    this._updateType();
    this._updateSize();
    this.play();
    this._dust.play();
  }

  name = 'exhibit';

  private _source = '';
  get source () { return this._source; }
  set source ( source ) {
    this._source = source;
    this._updateTexture();
  }

  get x() { return this.position.x; }
  set x( x ) {
    this.position.x = x;
  }

  get y() { return this.position.z; }
  set y( z ) {
    this.position.z = z;
  }

  get angle() { return MathUtils.radToDeg( this.rotation.y ) }
  set angle( angle ) {
    this.rotation.y = MathUtils.degToRad( angle );
  }

  private _ratio = 1;
  get ratio() { return this._ratio; }
  set ratio( ratio ) {
    this._ratio = ratio;
    this._updateSize();
  }

  private _size = 1;
  get size () { return this._size; }
  set size( size ) {
    this._size = size;
    this._updateSize();
  }

  play() {

    this._tween.to( { value: 1, }, 1000 )
    .easing( 	Easing.Elastic.Out)
    .onUpdate( ( { value } ) => {
      this._group.scale.set( value, value, value );
    } )
    .start();
  }

  private _updateSize() {


    if( !this._currentCanvas ) return;
    this._currentCanvas.scale.set( this._size * this._ratio , this._size, 1 );

    this._updateFrame();
  }

  constructor( exhibitOptions: ExhibitOptions = {} ) {
    super();

    const {
      index,
      size,
      typeIndex,
      colorIndex,
      x,
      y,
      source,
      ratio,
      angle,
    } = exhibitOptions;

    if( index ) this.index = index;

    if( size ) this._size = size ;
    this._typeIndex = typeIndex || 0;
    // if( typeIndex ) this._typeIndex = typeIndex;
    if( source ) this._source = source;
    if( ratio ) this._ratio = ratio;

    this.colorIndex = colorIndex || 0;

    if( x ) this.x = x;
    if( y ) this.y = y;
    if( angle ) this.angle = angle;

    this.add( this._group );
    Exhibit.model.then( ( gltf ) => {

      for( let index = 0; index <= 6; index++ ) {

        const frame = gltf.getObjectByName( `frame-${ index }` ) as Mesh;
        this._exhibitTypes.push( {
          canvas: gltf.getObjectByName( `canvas-${ index }` ) as Mesh,
          easel: gltf.getObjectByName( `easel-${ index }` ) as Mesh,
          frame: frame,
          frameGeometry: frame ? frame.geometry : undefined,
        })
      }

      this.add( this._dust );

      this._updateType();
      this._updateTexture();
      this._updateSize();

    } )

  }

  _updateType = () => {



    const exhibitType = this._exhibitTypes[ this._typeIndex ];

    while (this._group.children.length) {
      this._group.remove(this._group.children[0]);
    }

    const canvas = exhibitType.canvas.clone();
    this._currentCanvas = canvas;
    canvas.material = this._canvasMaterial;
    canvas.material.needsUpdate = true;
    canvas.castShadow = true;
    this._group.add( canvas );

    this._currentEasel = null;
    if( exhibitType.easel ) {
      // const easel = new Mesh( exhibitType.easel.geometry.clone(), this._material );
      const easel = exhibitType.easel.clone();
      easel.castShadow = true;
      easel.material = this._material;
      this._currentEasel = easel;
      this._group.add( easel );
    }

    this._currentFrame = null;
    if( exhibitType.frame ) {
      const frame = exhibitType.frame.clone();
      frame.geometry = exhibitType.frame.geometry.clone();
      frame.material = this._material;
      frame.castShadow = true;
      this._currentFrame = frame;
      this._group.add( frame );
      this.position.y = Math.random() * .2 + .1;
      // this.position.y = Math.random() + 1;
    }

  }

  _updateFrame() {

    // const positionEasel = this._currentEasel.geometry.attributes.position;
    if( !this._currentFrame ) return;
    // const positionEasel = this._exhibitTypes[ this._typeIndex ].frame?.geometry.attributes.position;
    const geometry = this._exhibitTypes[ this._typeIndex ].frameGeometry;

    if( !geometry ) return;
    const position = geometry.attributes.position;
    const positionFrame = this._currentFrame.geometry.attributes.position;

    let index = 0;
    for( index; index < position.count; index++ ) {

      const x = position.getX( index );
      const y = position.getY( index );
      if( x > 0 ) {
        positionFrame.setX( index, x - .1 + ( this.size / 10) * this.ratio );
      } else {
        positionFrame.setX( index, x + .1 - ( this.size / 10 )* this.ratio );
      }
      if( y > 0 ) {
        positionFrame.setY( index, y - .1 + ( this.size / 10 ) );
      } else {
        positionFrame.setY( index, y + .1 - ( this.size / 10 ) );
      }
    }
    positionFrame.needsUpdate = true;
  }

  private _updateTexture() {

    const mask = new Image();
    mask.src = '/textures/mask2.png';

    const image = new Image();
    image.src = this.source;

    const asyncMask = new Promise( ( resolve ) => mask.onload = () => resolve( mask ) );
    const asyncImage = new Promise( ( resolve ) => image.onload = () => resolve( image ) );

    Promise.all( [
      asyncMask,
      asyncImage,
    ]).then( () => {

      this._canvas.width = image.width;
      this._canvas.height = image.width;

      const context = this._canvas.getContext( '2d' ) as CanvasRenderingContext2D;

      context.drawImage( image, 0, 0 );
      context.drawImage( mask, 0, 0, image.width, image.width );
      // this._painting.material.map.needsUpdate = true;

      this._canvasMaterial.map = new CanvasTexture( this._canvas );
      this._canvasMaterial.needsUpdate = true;

      this._updateSize();
    })
  }

  tick() {
    this._dust.tick();

  }

}
