import { createAction, createSlice, PayloadAction, } from "@reduxjs/toolkit"
import { IndexedParams } from "@vaadin/router";

interface Search {
  [ key: string ]: string,
}

interface Params {
  [ id: string ]: string,
}

interface Router {
  pathname: string,
  hash: string,
  search: string,
  params: IndexedParams,
}

export interface RouterState extends Router {
  // isTweening: boolean,
}

// interface Parameters {
//   pathname: string,
//   search: Search,
// }

const initialState: RouterState = {
  // isTweening: false,
  pathname: '',
  hash: '',
  search: '',
  params: {},
};


// export const push = createAction( 'router/push' );

const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    silentPush: ( state, action: PayloadAction<string> ) => {
    },
    push: ( state, action: PayloadAction<string> ) => {
    },
    routerUpdated: ( state, action: PayloadAction<Router> ) => {
      state.pathname = action.payload.pathname;
      state.hash = action.payload.hash;
      state.search = action.payload.search;
      state.params = action.payload.params;
    },
  },
} );

const { actions, reducer } = routerSlice;

export const {
  routerUpdated,
  push,
  silentPush,
} = actions;

export default reducer;
