import { Router } from '@vaadin/router';
import { html, css, LitElement, PropertyValueMap } from 'lit'
import { customElement, state, } from 'lit/decorators.js'

import 'wired-elements';
import './components/pino-emmaus';
import './components/pino-languageswitch';
import './components/pino-snackbar';
import './components/pino-container';
import './components/pino-menu';
import './components/pino-rough';
import store, { RootState } from './store/store';
import './components/pino-loader';
import light from './color-schemes/light';
import dark from './color-schemes/dark';
import { transitionAbout, transitionGallery, transitionHome } from './store/transitions';
import { userLocaleUpdated, currentLocaleUpdated, menuUpdated } from './store/slices/app';
import { feedbackClosed, feedbackPushed } from './store/slices/feedback';
import { msg } from '@lit/localize';
import { push } from './store/slices/router';
import { serviceWorkerUpdated } from './store/middlewares/pwa-middleware';


/**
 * An example element.
 *
 * @slot - This element has a slot
 * @csspart button - The button
 */
@customElement('pino-app')
export class PinoApp extends LitElement {
  static styles = css`
    :host {
      display: flex;
      flex-direction: column;
      /* min-height: 100vh; */
      min-height: 100dvh;
      --pino-spacer-xxl: 48px;
      --pino-spacer-xl: 32px;
      --pino-spacer-lg: 24px;
      --pino-spacer: 16px;
      --pino-spacer-sm: 8px;
      --pino-spacer-xs: 4px;
      --pino-spacer-xxs: 2px;
    }
    main {
      flex-grow: 1;
      /* position: relative; */
    }
    #menu-button {
      background: white;
      margin-bottom: 16px;
    }
    .footer {
      text-align: right;
      position: sticky;
      bottom: 0;
      z-index: 2000;
    }
  `
  @state() private _isLoaded = false;
  @state() private _dark = false;
  @state() private _feedbackMessage = '';
  @state() private _feedbackOpen = false;
  @state() private _feedbackAction = '';
  @state() private _feedbackIcon = '';
  @state() private _menu = false;
  @state() private _makingOf = false;

  stateChanged = () => {

    const state = store.getState() as RootState;

    this._feedbackMessage = state.feedback.message;
    this._feedbackOpen = state.feedback.open;
    this._feedbackAction = state.feedback.action;
    this._feedbackIcon = state.feedback.icon;

    if( this._menu !== state.app.menu ) {
      this._menu = state.app.menu;
    }
    if( this._makingOf !== state.app.makingOf ) {
      this._makingOf = state.app.makingOf;
    }
    if( this._isLoaded !== state.loader.isLoaded ) {
    }
    if( this._isLoaded !== state.loader.isLoaded ) {
      this._isLoaded = state.loader.isLoaded;
    }
    const dark = state.app.colorScheme === 'dark';
    if( this._dark !== dark ) {
      this._dark = dark;
    }
  }

  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {

    store.subscribe( this.stateChanged );

    const router = new Router( this.shadowRoot?.querySelector( 'main' ) );

    router.setRoutes( [ {
      path: '/',
      component: 'page-home',
      action: async () => {
        store.dispatch( transitionHome() )
        await import( './pages/page-home' );
      },
    }, {
      path: '/contact',
      component: 'page-contact',
      action: async () => {
        await import( './pages/page-contact' );
      },
    }, {
      path: '/install',
      component: 'page-install',
      action: async () => {
        await import( './pages/page-install' );
      },
    }, {
      path: '/making-of',
      component: 'page-makingof',
      action: async () => {
        await import( './pages/page-makingof' );
      },
    }, {
      path: '/login',
      component: 'page-login',
      action: async () => {
        await import( './pages/page-login' );
      },
    }, {
      path: '/authorize',
      component: 'page-authorize',
      action: async () => {
        await import( './pages/page-authorize' );
      },
    }, {
      path: '/imprint',
      component: 'page-imprint',
      action: async () => {
        await import( './pages/page-imprint' );
      },
    }, {
      path: '/explore',
      component: 'page-explore',
      action: async () => {
        await import( './pages/page-explore' );
      },
    }, {
      path: '/privacy',
      component: 'page-privacy',
      action: async () => {
        await import( './pages/page-privacy' );
      },
    }, {
      path: '/about',
      component: 'page-about',
      action: async () => {
        // store.dispatch( transitionAbout() )
        await import( './pages/page-about' );
      },
    }, {
      path: '/gallery/:id?',
      component: 'page-gallery',
      action: async () => {
        await import( './pages/page-gallery' );
      },
    }, {
      path: '/create',
      component: 'page-create',
      action: async () => {
        await import( './pages/page-create' );
      },
    }

    ] )
  }

  render() {
    const onFeedback = () => {

      const { path, actionType }= (store.getState() as RootState).feedback;

      if( actionType === 'refresh' ) {
        store.dispatch( serviceWorkerUpdated() )
      } else if ( actionType === 'link' && path ) {
        store.dispatch( push( path ) )
        store.dispatch( feedbackClosed() );
      } else {
        store.dispatch( feedbackClosed() );
      }
    }
    return html`

      ${ this._dark ? dark : light }
      ${ this._isLoaded ? html`
      <pino-emmaus></pino-emmaus>
      <!-- <pino-container>
        <pino-languageswitch
        @locale-change="${ ( event: CustomEvent ) => {
          // store.dispatch( feedbackPushed({ message: 'hello!', action: 'Go now' }))
          store.dispatch( userLocaleUpdated( event.detail ) );
          store.dispatch( currentLocaleUpdated( event.detail ) );
        }}"
        ></pino-languageswitch>
      </pino-container> -->
      ` : html`` }
      <!-- <pino-loader></pino-loader> -->

      <!-- <pino-header></pino-header> -->
      <main></main>
      <pino-container class="footer">
        <wired-button
        aria-labelledby="menu-icon"
        id="menu-button"
        @click="${ () => {
          store.dispatch( menuUpdated( true ) );
        }}"
        >
        <img id="menu-icon" alt="Menu" src="/icons/menu.svg" width="32" height="32">
        </wired-button>
      </pino-container>
      <pino-menu
        .open="${ this._menu }"
        .makingOf="${ this._makingOf }"
        @close="${ () => {
          store.dispatch( menuUpdated( false ));
        }}"
        @link="${ ( event: CustomEvent ) => {
          store.dispatch( push( event.detail ));
        }}"
        @locale-change="${ ( event: CustomEvent ) => {
          // store.dispatch( feedbackPushed({ message: 'hello!', action: 'Go now' }))
          store.dispatch( userLocaleUpdated( event.detail ) );
          store.dispatch( currentLocaleUpdated( event.detail ) );
        }}"
      ></pino-menu>
      <pino-snackbar
        .open="${ this._feedbackOpen }"
        .action="${ this._feedbackAction }"
        .icon="${ this._feedbackIcon }"
        @close="${ onFeedback }"
      >${ this._feedbackMessage }</pino-snackbar>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'pino-app': PinoApp,
  }
}
