import { createAsyncThunk, createSlice, PayloadAction, } from "@reduxjs/toolkit"
import { PerspectiveCamera, Vector3 } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { PinoState } from "../../models/pino";
import { cameraPositions } from "../../resources";



interface Position {
  x: number,
  y: number,
  z: number,
}

// type Offset = 'left' | 'center' | 'right';

export interface Offset {
  name: string,
  value: number,
}

const offsets: Offset[] = [
  { name: 'left', value: 0.25, },
  { name: 'center', value: 0, },
  { name: 'right', value: -0.25, },
]

export interface CameraPosition {
  name: string,
  path: string,
  camera: Position,
  target: Position,
  offset: Offset,
}


export interface EmmausState {
  currentCameraPosition: CameraPosition | null;
  cameraPositions: CameraPosition[],
  pino: PinoState,
}


const initialState: EmmausState = {
  currentCameraPosition: null,
  cameraPositions: [],
  pino: 'drawing',
};

export const fetchCameraPositions = createAsyncThunk(
  'fetch/cameraPositions',
  async ( _, thunkAPI ) => {

    cameraPositions
      .then( scene => {
        const cameraPositions: CameraPosition[]  = scene.children.map( child => {

          const camera = child.children.find( child => {
            const perspectiveCamera = child as PerspectiveCamera;
            return perspectiveCamera.isPerspectiveCamera
          } );

          const target = child.children.find( child => {
            const perspectiveCamera = child as PerspectiveCamera;
            return !perspectiveCamera.isPerspectiveCamera
          } );

          if( !camera || !target ) throw Error( 'Missing cameras or targets!')
            const worldCamera = new Vector3()
            camera.getWorldPosition( worldCamera );
            const worldTarget = new Vector3();
            target.getWorldPosition( worldTarget );

          const offset = offsets.find( offset => offset.name === child.userData.offset );
          if( !offset ) throw Error( 'No offset found!' );

          const path = child.userData.path;
          if( !path ) throw Error( 'No path found!' );


          const cameraPosition = {
            name: child.name,
            path: path,
            camera: { x: worldCamera.x, y: worldCamera.y, z: worldCamera.z },
            target: { x: worldTarget.x, y: worldTarget.y, z: worldTarget.z },
            offset: offset,
          };

          return cameraPosition;

        })
        thunkAPI.dispatch( emmausSlice.actions.cameraPositionsFetched( cameraPositions ) );
        return true;
      })
      .catch( error => {
        return false;
      })
  }
)

const emmausSlice = createSlice({
  name: 'emmaus',
  initialState,
  reducers: {
    currentCameraPositionUpdated: ( state, action: PayloadAction<string>) => {
      const cameraPosition = state.cameraPositions
        .find( cameraPosition => cameraPosition.path === action.payload );
      if( cameraPosition ) state.currentCameraPosition = cameraPosition;
    },
    pinoUpdated: ( state, action: PayloadAction<PinoState>) => {
      state.pino = action.payload;
    },
    cameraPositionsFetched: ( state, action: PayloadAction<CameraPosition[]>) => {
      state.cameraPositions = action.payload;
    },
  },
} );

const { actions, reducer } = emmausSlice;

export const {
  currentCameraPositionUpdated,
  cameraPositionsFetched,
  pinoUpdated,
} = actions;

export default reducer;
