import { html, css, nothing } from 'lit'
import { customElement, property, } from 'lit/decorators.js'
import { ComponentElement } from '../elements/component-element'
import './pino-rough';

/**
 * An example element.
 *
 * @slot - This element has a slot
 * @csspart button - The button
 */

@customElement( 'pino-snackbar' )
export class PinoSnackbar extends ComponentElement {

  @property({ type: Boolean, reflect: true, }) open = false;
  @property({ type: String }) action = '';
  @property({ type: String }) icon = 'info';

  static styles = [
    super.styles,
    css`
     :host {
        display: block;
        position: fixed;
        top: 100vh;
        top: 100dvh;
        left: 50%;
        right: 0;
        will-change: transform;
        transform: translate3d(-50%, 0, 0);
        transition-property: visibility, transform;
        transition-duration: 0.2s;
        visibility: hidden;
        padding: 16px;
        z-index: 3000;
        width: 100%;
      }
      button {
        all: unset;
        cursor: pointer;
        padding: 12px;
        background-color: rgba( 20, 20, 20, 1 );
        color: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;
      }
      .message {
        flex-grow: 1;
      }
      .action {
        text-decoration: underline;
      }

      :host([open]) {
        visibility: visible;
        transform: translate3d(-50%, -100%, 0);
      }
      @media (min-width: 460px) {
        :host {
          width: auto;
        }
        button {
          width: 420px;
          margin: auto;
        }
      }
  ` ]

  render() {
    return html`
    <button
      type="button"
      @click="${ () => {
        this.dispatchEvent( new CustomEvent( 'close' ) );
      }}"
    >
    ${ this.open ? html`
            <pino-rough
              .dark="${ true }"
              class="icon"
              .delay="${ 0 }"
              source="/icons/${ this.icon }.svg"
            ></pino-rough>
    ` : nothing }

    <div class="message">
      <slot></slot>
    </div>
    <div class="action">
      ${ this.action }
    </div>
  </button>
    `
  }
}


declare global {
  interface HTMLElementTagNameMap {
    'pino-snackbar': PinoSnackbar,
  }
}
