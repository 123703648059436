import { html, css, } from 'lit'
import { customElement, property, } from 'lit/decorators.js'
import { ComponentElement } from '../elements/component-element'

/**
 * An example element.
 *
 * @slot - This element has a slot
 * @csspart button - The button
 */

@customElement( 'pino-container' )
export class PinoContainer extends ComponentElement {

  @property({ type: Boolean, attribute: 'dark', reflect: true, }) dark = false;
  static styles = [
    super.styles,
    css`
    :host {
      display: block;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
        padding-right: var( --pino-spacer, 16px );
        padding-left: var( --pino-spacer, 16px );
    }
    @media (min-width: 1200px) {
      :host {
        max-width: 1140px;
      }
    }
  ` ]

  render() {
    return html`<slot></slot>`
  }
}


declare global {
  interface HTMLElementTagNameMap {
    'pino-container': PinoContainer,
  }
}
