import { createAction, createListenerMiddleware } from "@reduxjs/toolkit";
import { init, refreshNeeded, } from "../slices/app";
import { registerSW } from 'virtual:pwa-register'
import store from "../store";
import { feedbackPushed } from "../slices/feedback";
import { msg } from "@lit/localize";

const updateServiceWorker = registerSW({
  onNeedRefresh() {

    store.dispatch( refreshNeeded() );

    store.dispatch( feedbackPushed( {
      message: msg( 'A new update is available', { id: 'new-version' } ),
      action: msg( 'Restart', { id: 'restart' } ),
      actionType: 'refresh',
      icon: 'refresh',
      path: '/',
    }))
  },
  immediate: true,
  onRegisterError( error ) {
    console.log( 'Error registering sw:', error );
  },
  onOfflineReady() {
    store.dispatch( feedbackPushed( {
      icon: 'plugs',
      message: msg( 'Pino Art can now be used offline'),
    }))
  },
})

export const addToHomescreenFired = createAction( 'pwa/addToHomescreenFired' );
export const serviceWorkerUpdated = createAction( 'pwa/serviceWorkerUpdated' );

// https://stackoverflow.com/questions/51503754/typescript-type-beforeinstallpromptevent
// https://stackoverflow.com/a/67171375
interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

// This variable will save the event for later use.
let deferredPrompt: BeforeInstallPromptEvent | null = null;

window.addEventListener('beforeinstallprompt', (e) => {
  console.log('testi')
  // Prevents the default mini-infobar or install dialog from appearing on mobile
  // e.preventDefault();
  // Save the event because you'll need to trigger it later.
  deferredPrompt = e;
  // Show your customized install prompt for your PWA
  // Your own UI doesn't have to be a single element, you
  // can have buttons in different locations, or wait to prompt
  // as part of a critical journey.
});

const pwaListenerMiddleware = createListenerMiddleware();

pwaListenerMiddleware.startListening({
  actionCreator: addToHomescreenFired,
  effect: async () => {

    if( deferredPrompt ) deferredPrompt.prompt();
  }
} )

pwaListenerMiddleware.startListening({
  actionCreator: serviceWorkerUpdated,
  effect: async () => {

    updateServiceWorker();

  }
} )


export default pwaListenerMiddleware.middleware;
