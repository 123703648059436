import { configureLocalization } from "@lit/localize";
import { sourceLocale } from "./generated/locale-codes";
import { targetLocales } from "./generated/locale-codes";

export const { getLocale, setLocale } = configureLocalization( {
  sourceLocale,
  targetLocales,
  // @vite-ignore
  loadLocale: ( locale ) => import( `./generated/${ locale }.ts` ),
} );
