import { createSlice, PayloadAction, } from "@reduxjs/toolkit"
import { Exhibit } from '../exhibits';

export interface CreateState {
  data: Exhibit | null,
  positioning: boolean,
}

const initialState: CreateState = {
  data: null,
  positioning: false,
};

const authSlice = createSlice({
  name: 'create',
  initialState,
  reducers: {
    positioned: ( state, action: PayloadAction<boolean>) => {
      state.positioning = action.payload;
    },
  },
} );

const { actions, reducer } = authSlice;

export const {
  positioned,
} = actions
export default reducer;
