import { createAsyncThunk, } from "@reduxjs/toolkit";
import { currentCameraPositionUpdated, pinoUpdated } from "./slices/emmaus";
import { push } from "./slices/router";

const duration = 1000;

export const transitionHome = createAsyncThunk(
  `transiton/home`, async ( _, thunkAPI ) => {

    // thunkAPI.dispatch( currentCameraPositionUpdated( 'home' ) );

    setTimeout( () => {
      // thunkAPI.dispatch( push( { pathname: '/' }) );
    }, duration );

  }
)

export const transitionLogin = createAsyncThunk(
  `transiton/home`, async ( _, thunkAPI ) => {

    // thunkAPI.dispatch( currentCameraPositionUpdated( 'login' ) );

    setTimeout( () => {
      // thunkAPI.dispatch( push( { pathname: '/login' }) );
    }, duration );

  }
)

export const transitionGallery = createAsyncThunk(
  `transiton/home`, async ( _, thunkAPI ) => {

    // thunkAPI.dispatch( currentCameraPositionUpdated( 'gallery' ) );

    setTimeout( () => {
      // thunkAPI.dispatch( push( { pathname: '/gallery' }) );
    }, duration );

  }
)

export const transitionAbout = createAsyncThunk(
  `transiton/home`, async ( _, thunkAPI ) => {

    // thunkAPI.dispatch( currentCameraPositionUpdated( 'about' ) );

    setTimeout( () => {
      // thunkAPI.dispatch( pinoUpdated( 'turning-around' ) );
      // thunkAPI.dispatch( push( { pathname: '/about' }) );
    }, duration );

  }
)
