import { html, css, LitElement, CSSResultGroup } from 'lit'
import { customElement, } from 'lit/decorators.js'

/**
 * An example element.
 *
 * @slot - This element has a slot
 * @csspart button - The button
 */

export class ComponentElement extends LitElement {
  static styles: CSSResultGroup = css`
    :host {
      display: block;
      font-family: sans-serif;
    }
    :host *, :host(*), *, ::after, ::before {
      box-sizing: border-box;
    }
    wired-card {
      background-color: rgba( 255, 255, 255, .7 );
      width: 100%;
      margin-bottom: 16px;
      padding: 24px;
    }
  `
}
