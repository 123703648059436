import { html, css, PropertyValueMap, } from 'lit'
import { customElement, query, state, } from 'lit/decorators.js'
import { ComponentElement } from '../elements/component-element'

import './pino-rough';
import TWEEN, { Tween, Easing, } from '@tweenjs/tween.js';
import store, { RootState } from '../store/store';

/**
 * An example element.
 *
 * @slot - This element has a slot
 * @csspart button - The button
 */

@customElement('pino-loader')
export class PinoLoader extends ComponentElement {

  @query( 'canvas' ) _canvas!: HTMLCanvasElement;
  @query( 'dialog' ) _dialog!: HTMLDialogElement;

  static styles = [
    super.styles,
    css`
    :host {
      display: flex;
      background: white;
      position: fixed;
      top: 0;
      left: 0;
    }
    dialog {
      border: none;
    }
    dialog::backdrop {
      all: unset;

    }

    progress {
      margin-top: 16px;
      appearance: none;
      width: 100%;
      height: 5px;
    }
    progress::-webkit-progress-value {
      background: black;
    }
  ` ]

  @state() private _loaded = 0;
  @state() private _total = 0;

  stateChanged = () => {

    const state = store.getState() as RootState;

    this._loaded = state.loader.loaded;
    this._total = state.loader.total;
  }

  render() {
    return html`
    <dialog>
      <pino-rough
        source="/pino.svg"
      ></pino-rough>
      <progress max="${ this._total }" value="${ this._loaded }"></progress>
    </dialog>
    `
  }
  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {

    this._dialog.showModal()
    store.subscribe( this.stateChanged );
  }

}


declare global {
  interface HTMLElementTagNameMap {
    'pino-loader': PinoLoader,
  }
}
