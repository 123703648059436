import { html, css, PropertyValueMap } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { ComponentElement } from "../elements/component-element";
import "wired-elements";
import "./pino-languageswitch";
import './pino-container';
import { msg } from "@lit/localize";

/**
 * An example element.
 *
 * @slot - This element has a slot
 * @csspart button - The button
 */

@customElement("pino-menu")
export class PinoMenu extends ComponentElement {

  @property({ type: Boolean, reflect: true }) open = false;
  @property({ type: Boolean, attribute: 'making-of' }) makingOf = false;

  @query("dialog", false) _dialog!: HTMLDialogElement;

  static styles = [
    super.styles,
    css`
      :host {
        display: flex;
        position: relative;
      }
      dialog {
        padding: 0;
        background-color: transparent;
        border: none;
        outline: none;
        top: auto;
        /* bottom: 16px;
        right: 16px; */
        margin: unset;
        /* inset-inline-start: auto; */
        /* inset-inline-end: auto; */
        /* inset-block-start: auto; */
        /* inset-block-end: auto; */
        width: auto;
          /* transform-origin: bottom center; */
      }
      dialog::backdrop {
      }
      :host([open]) dialog::backdrop {
        animation: backdrop-show 200ms ease forwards;
      }
      dialog.hide::backdrop {
        animation: backdrop-hide 200ms ease forwards;
      }
      @keyframes backdrop-show {
        from { background-color: rgba(255, 255, 255, .0 ) }
        to { background-color: rgba(255, 255, 255, .8 )}
      }
      @keyframes backdrop-hide {
        from { background-color: rgba(255, 255, 255, .8 )}
        to { background-color: rgba(255, 255, 255, .0 )}
      }
      :host([open]) dialog {
        animation: dialog-show 200ms ease forwards;
      }
      dialog.hide {
        animation: dialog-hide 200ms ease forwards;
        /* animation-direction: reverse !important; */
        /* background-color:pink; */
      }
      @keyframes dialog-show {
        from { transform: translateY( 100% ); }
        to { transform: translateY(0%); }
      }
      @keyframes dialog-hide {
        to { transform: translateY(100%); }
      }

      dialog::backdrop {
        background-color: rgba(255, 255, 255, 0.8);
      }
      button {
        all: unset;
        display: block;
        width: 100%;
        padding: 16px 0;
        cursor: pointer;
      }
      .sub {
        display: flex;
        flex-direction: column;
        /* margin-bottom: 16px; */
      }
      wired-divider {
        margin: 16px 0;
      }
      pino-container {
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: flex-end;
      }
      wired-card {
        width: auto;
        background: white;
      }
    `,
  ];

  @property({ type: String }) source = "";
  @property({ type: Number }) ratio = 1;
  @property({ type: Number }) size = 1;
  @property({ type: Number, attribute: "color-index" }) colorIndex = 0;
  @property({ type: Number, attribute: "type-index" }) typeIndex = 0;
  @property({ type: Number }) angle = 0;

  protected firstUpdated( _changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {}
  protected update( changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {

    super.update( changedProperties );

    if( changedProperties.has( 'open' ) && this.open) {

      this._dialog.showModal();

    } else if( changedProperties.has( 'open' ) && changedProperties.get( 'open' ) === true ) {

      this._dialog.classList.add('hide');
      this._dialog.addEventListener('animationend', this._onAnimationend, false);
    }
  }

  private _onAnimationend = () => {
    this._dialog.classList.remove("hide");
    this._dialog.close();
    this._dialog.removeEventListener( "animationend", this._onAnimationend, false);
  };

  render() {
    const onDialog = ( event: MouseEvent ) => {
      if( event.target.closest('div') ) return;
      this.dispatchEvent( new CustomEvent( 'close'));

    }
    const onLink = ( path: string ) => {
      this.dispatchEvent( new CustomEvent( 'link', {detail: path } ) );
      this.dispatchEvent( new CustomEvent( 'close' ) );
    }
    return html`
    <dialog @click="${ onDialog }">
      <pino-container>
      <wired-card>
        <div class="sub">
          <button
          @click="${ () => onLink( '/')}"
          >
            ${ msg( html`Back to start screen`, { id: 'back-to-start-screen' } ) }
          </button>
        </div>
        <hr>
        <!-- <wired-divider></wired-divider> -->
        <div class="sub">
          <button
            @click="${ () => onLink( '/explore')}"
          >
          ${ msg( 'Explore', { id: 'title-explore' } ) }
          </button>
          <button
            @click="${ () => onLink( '/gallery/0')}"
          >
          ${ msg( 'Gallery', { id: 'title-gallery' } ) }
          </button>
          <button
            @click="${ () => onLink( '/about')}"
          >
          ${ msg( 'About Pino', { id: 'title-about' } ) }
          </button>
          <button
            @click="${ () => onLink( '/contact' )}"
          >
          ${ msg( 'Contact', { id: 'title-contact' } ) }
          </button>
        </div>
        <hr>
          <button
            @click="${ () => onLink( '/install' )}"
          >
          ${ msg( 'Install', { id: 'title-install' } ) }
          </button>
        <hr>
        <!-- <wired-divider></wired-divider> -->
        <div class="sub">
          <button
            @click="${ () => onLink( '/imprint' )}"
          >
          ${ msg( 'Imprint', { id: 'title-imprint' } ) }
          </button>
          <button
            @click="${ () => onLink( '/privacy' )}"
          >
          ${ msg( 'Privacy Policy', { id: 'title-privacy' } ) }
          </button>
          ${ this.makingOf ? html`<button
            @click="${ () => onLink( '/making-of' )}"
          >
          ${ msg( 'Making of', { id: 'title-making-of' } ) }
          </button>` : html`` }
        </div>
        <hr>

          <!-- <h3>Change Language</h3> -->
          <pino-languageswitch
            @locale-change="${ ( event: CustomEvent ) => {
              this.dispatchEvent( new CustomEvent( 'locale-change', {detail: event.detail }))
              // store.dispatch( feedbackPushed({ message: 'hello!', action: 'Go now' }))
              // store.dispatch( userLocaleUpdated( event.detail ) );
              // store.dispatch( currentLocaleUpdated( event.detail ) );
            }}"
          ></pino-languageswitch>
        </wired-card>
    </pino-container>
    </dialog>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "pino-menu": PinoMenu;
  }
}
