import { createListenerMiddleware } from "@reduxjs/toolkit";
import { currentCameraPositionUpdated, pinoUpdated } from "../slices/emmaus";
import { routerUpdated } from "../slices/router";
import store, { RootState } from "../store";

const animationListenerMiddleware = createListenerMiddleware();

animationListenerMiddleware.startListening({

  actionCreator: routerUpdated,
  effect: async ( action, listenerApi) => {

    const state = listenerApi.getState() as RootState;


    if ( action.payload.pathname === '/about' && state.emmaus.pino !== 'turning-around' ) {
      setTimeout( () => {
        store.dispatch( pinoUpdated( 'turning-around' ) );
      }, 1000 );
    } else if ( action.payload.pathname !== '/about' && state.emmaus.pino !== 'drawing' ) {
        store.dispatch( pinoUpdated( 'drawing' ) );
    }
    // defaultMaterial.color = new Color( state.app.colorScheme === 'light' ? 0xffffff : 'hsl(207, 50%, 2%)');

  }
})

export default animationListenerMiddleware.middleware;
