import { combineReducers, Reducer } from "@reduxjs/toolkit";
import auth from "./slices/auth";
import app from "./slices/app";
import user from "./slices/user";
import exhibits from './slices/exhibits';
import emmaus from './slices/emmaus';
import create from './slices/pages/create';
import loader from './slices/loader';
import router from './slices/router';
import feedback from './slices/feedback';

const pages = combineReducers( {
  create,
})

const rootReducer = combineReducers({
  loader,
  emmaus,
  feedback,
  auth,
  app,
  user,
  router,
  exhibits,
  pages,
} )

export default rootReducer;
