import {
  Mesh,
  Group,
  Vector3,
  PositionalAudio,
} from "three";

import { Model } from "./model";
import { Dust } from "./dust";
import { Tween, Easing, } from "@tweenjs/tween.js";
import { birdFlyingModel, birdModel,
  // chirp1, chirp2, chirp3, chirp4, chirp5, currentListener
} from "../resources";
import { getRandomPastelMaterial } from "../materials";
import { Area } from "./flock";

interface Options {
}

export class Bird extends Model {

  private _tween = new Tween( this.position );

  private _bird = new Group();
  private _birdFlying = new Group();

  private _head!: Mesh;
  private _leftWing!: Mesh;
  private _rightWing!: Mesh;

  private _chirps: PositionalAudio[] = [];

  // private _dust = new Dust();

  private _escapePoint = new Vector3();
  public currentPosition = new Vector3();
  public currentArea!: Area;

  play() {

    // this._tween.to( { value: 1, }, 1000 )
    // .easing( 	Easing.Elastic.Out)
    // .onUpdate( ( { value } ) => {
    //   this._group.scale.set( value, value, value );
    // } )
    // .start();
  }

  land( startPoint: Vector3 ) {

    this.position.set( startPoint.x, startPoint.y, startPoint.z );
    this._birdFlying.visible = true;

    this._tween = new Tween( this.position )
    this._tween.to( this.currentPosition, 3000 )
    .onStart( () => {
      this.lookAt( this.currentPosition );
    } )
    .onUpdate ( ( _, elapsed ) => {
      const multiplier = 1280;
      this._leftWing.rotation.z = Math.sin( elapsed * 128);
      this._rightWing.rotation.z = -Math.sin( elapsed * 128 );
    } )
    .onComplete( () => {
      this._birdFlying.visible = false;
      this._bird.visible = true;
      this.dispatchEvent( { type: 'landed' },  );
    } )
    .start();
  }

  walk() {
    const distance = this.position.distanceTo( this.currentPosition );

    this._tween = new Tween( this.position )
    this._tween.to( this.currentPosition, 1000 )
    .onStart( () => {
      this.lookAt( this.currentPosition );
      this._head.rotation.y = 0;
      this.rotation.x = 0;
      this.rotation.z = 0;

    })
    .onUpdate( ( _, elapsed ) => {
      this.position.y = this.position.y + 0.025 * Math.sin( elapsed *Math.PI*8 + Math.PI )+ .025;
    })
    .onComplete( () => {
      this._looking()
    })
    .start();
  }

  private _looking( index: number = 0 ) {
    this._head.rotation.y = Math.random() * 4 - 2;
    setTimeout( () => {
      if( Math.floor( Math.random() * 10  ) < 4 || index > 5 ) {
        this.dispatchEvent( { type: 'walked' },  );
      } else {
        this._looking( index + 1 );
      }

    }, Math.random() * 1000 + 500 );
  }

  getRandomPointInArea( area: Area ) {
    const randomIndex = Math.floor( Math.random() * area.positions.length );
    return area.positions[ randomIndex ]
  }

  constructor( area: Area, escapePoint: Vector3, options: Options = {} ) {
    super();

    const {
    } = options;

    this._escapePoint = escapePoint;
    this.currentArea = area;
    this.add( this._bird );
    this.add( this._birdFlying );
    this._bird.visible = false;
    this._birdFlying.visible = false;

    Promise.all( [ birdModel, birdFlyingModel ] ).then( ( [ bird, birdFlying ] ) => {


      const material = getRandomPastelMaterial();

      const body = bird.children.find( child => child.name.includes( 'bird-body' )) as Mesh;
      if( body ) {
        const clone = body.clone();
        clone.material = material;
        this._bird.add( clone );
        clone.castShadow = true;
      }

      const head = bird.children.find( child => child.name.includes( 'bird-head' )) as Mesh;
      if( head ) {
        const clone = head.clone();
        clone.material = material;
        this._head = clone;
        this._bird.add( clone );
      }

      const flying = birdFlying.children.find( child => child.name.includes( 'bird-flying' )) as Mesh;
      if( flying ) {
        const clone = flying.clone();
        clone.material = material;
        this._birdFlying.add( clone );
        clone.castShadow = true;
      }

      const leftWing = birdFlying.children.find( child => child.name.includes( 'left-wing' )) as Mesh;
      if( leftWing ) {
        const clone = leftWing.clone();
        clone.material = material;
        this._leftWing = clone;
        this._birdFlying.add( clone );
      }

      const rightWing = birdFlying.children.find( child => child.name.includes( 'right-wing' )) as Mesh;
      if( rightWing ) {
        const clone = rightWing.clone();
        clone.material = material;
        this._rightWing = clone;
        this._birdFlying.add( clone );
      }
    } )

    // Promise.all( [ chirp1, chirp2, chirp3, chirp4, chirp5 ] )
    //   .then( chirps => {
    //     this._chirps = chirps.map( buffer => {
    //       const audio = new PositionalAudio( currentListener );
    //       audio.setBuffer( buffer );
    //       audio.setRefDistance( .5 );
    //       this.add( audio );
    //       return audio;
    //     } )

    //     // this._chirpRandomly();
    //   } )


  }

  private _chirpRandomly() {

    const randomIndex = Math.floor( Math.random() * this._chirps.length );
    const audio = this._chirps[ randomIndex ];

    audio.play();

    setTimeout( () => {
      this._chirpRandomly();
    }, Math.random() * 5000 + 5000  );

  }

  tick() {
    // if( this._birdFlying.visible ) {
    //   this._leftWing.rotateY()
    // }
  }

}
