import { html, css, } from 'lit'
import { customElement, property, } from 'lit/decorators.js'
import { ComponentElement } from '../elements/component-element'
import { allLocales } from '../locales/generated/locale-codes';

/**
 * An example element.
 *
 * @slot - This element has a slot
 * @csspart button - The button
 */

@customElement( 'pino-languageswitch' )
export class PinoLanguageswitch extends ComponentElement {

  @property({ type: Boolean, attribute: 'dark', reflect: true, }) dark = false;

  static styles = [
    super.styles,
    css`
      :host {
        display: block;
      }
      button {
        all: unset;
        padding: 4px 8px;
        min-width: 24px;
        text-align: center;
        border: 1px dashed transparent;
        cursor: pointer;
      }
      button:is(:hover,:focus) {
        border-color: black;
      }
      button:is(:active) {
        background-color: rgba( 0, 0, 0, .1 );
      }
  ` ]

  render() {

    return html`${ allLocales.map( ( locale, index ) => html`
      ${ index ? '|' : '' }
      <button
        type="button"
        @click="${ () => {
          this.dispatchEvent( new CustomEvent( 'locale-change', {detail: locale } ) )
        }}"
      >${ locale.slice( 0, 2 ) }</button>
      `)}`
  }
}


declare global {
  interface HTMLElementTagNameMap {
    'pino-languageswitch': PinoLanguageswitch,
  }
}
