import { configureStore, } from '@reduxjs/toolkit'
import storage from './middlewares/storage';
import loader from './middlewares/loader';
import rootReducer from './reducers'
import router from './middlewares/router';
import mobile from './middlewares/mobile';
import colorScheme from './middlewares/color-scheme';
import animation from './middlewares/animation';
import locale from './middlewares/locale-middleware';
import feedback from './middlewares/feedback-middleware';
import pwa from './middlewares/pwa-middleware';


export const store = configureStore({
  devTools: import.meta.env.DEV,
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(
    storage,
    pwa,
    feedback,
    locale,
    router,
    mobile,
    loader,
    colorScheme,
    animation,
  ),
})

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
