import { html, css, PropertyValueMap, } from 'lit'
import { customElement, property, query, state, } from 'lit/decorators.js'
import { ComponentElement } from '../elements/component-element'
import {
  MOUSE,
  TOUCH,
  AmbientLight,
  DirectionalLight,
  DirectionalLightHelper,
  Group,
  MathUtils,
  Mesh,
  Raycaster,
  Vector2,
  Vector3,
  CameraHelper,
} from 'three';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { MapControls } from 'three/examples/jsm/controls/MapControls';
import './pino-gl';
import { PinoGL } from './pino-gl';
import { Emmaus } from '../models/emmaus';
import store, { RootState } from '../store/store';

import { Exhibit } from '../store/slices/exhibits'
import { Exhibit as ExhibitModel } from '../models/exhibit';
import { Cameraman } from '../models/cameraman';
import { CameraPosition, } from '../store/slices/emmaus';
import { Model } from '../models/model';
import { emmausModel } from '../resources';
import { push } from '../store/slices/router';
import { Links } from '../models/links';

/**
 * An example element.
 *
 * @slot - This element has a slot
 * @csspart button - The button
 */

@customElement( 'pino-emmaus' )
export class PinoEmmaus extends ComponentElement {

  static styles = [
    super.styles,
    css`
    :host {
      display: block;
      position: fixed;
      top: 0;
    }
  ` ]

  @property( { type: String, }) src = '';
  @property( { type: Number, }) ratio = 1;
  @property( { type: Number, }) size = 1;

  @query( 'pino-gl' ) pinoGL!: PinoGL;

  emmaus = new Emmaus();

  @state() _width = window.innerWidth;
  @state() _height = window.innerHeight;
  private  _explore = false;
  private _moving = false;

  controls!: OrbitControls

  context!: CanvasRenderingContext2D;
  _exhibits: Exhibit[] = [];
  group = new Group();

  private _cameraman!: Cameraman;
  private _cameraPosition!: CameraPosition;
  private _isMobile = false;

  get exhibits () { return this._exhibits; }
  set exhibits ( exhibits ) {
    this._exhibits = exhibits;
    this.updateExhibits();
  }

  updateExhibits() {


    // this.group.traverse( node => this.group.remove( node ) );

    this._exhibits.forEach( (exhibit, index ) => {
      const exhibitModel = new ExhibitModel( {
        size: exhibit.size,
        typeIndex: exhibit.typeIndex,
        colorIndex: exhibit.colorIndex,
        x: exhibit.x,
        ratio: exhibit.ratio,
        y: exhibit.y,
        index: index,
        source: exhibit.thumbnail,
        angle: exhibit.angle,
      });
      exhibitModel.castShadow = true;
      // exhibitModel.position.set( exhibit.x, 0, exhibit.y );
      // exhibitModel.src = exhibit.texture;
      // exhibitModel.size = exhibit.size;
      // exhibitModel.typeIndex = exhibit.typeIndex;
      // exhibitModel.rotation.y = MathUtils.degToRad( exhibit.angle );

      ExhibitModel.model.then( () => {
        exhibitModel.children.forEach( model => {
          const mesh = model as Mesh;
          mesh.castShadow = true;
        })
      })
      this.group.add( exhibitModel );


    })

  }

  stateChanged = () => {

    const state = store.getState() as RootState;

    if( state.exhibits.exhibits !== this.exhibits ) {
      this.exhibits = state.exhibits.exhibits;
    }

    const explore = state.router.pathname.includes( '/explore' );
    if(  explore !== this._explore ) {
      this._explore = explore;
    }
    if( state.emmaus.pino !== this.emmaus.pino.pinoState ) {
      this.emmaus.pino.pinoState = state.emmaus.pino;
      // if( state.emmaus.pino === 'turning-around') {
      //   this.emmaus.pino.turnAround();
      // }
    }
    if( state.emmaus.currentCameraPosition && state.emmaus.currentCameraPosition !== this._cameraPosition ) {
      this._cameraPosition = state.emmaus.currentCameraPosition;
      // this._cameraman.playCameraPosition( this._cameraPosition );
      this._cameraman.playCameraPosition( this._cameraPosition );
      this._moving = true;
    }
    if( state.app.isMobile !== this._isMobile ) {
      this._isMobile = state.app.isMobile;
      this._cameraman.isMobile = this._isMobile;
    }
  }

  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {


    // this.pinoRenderer.renderer;
    // this.pinoRenderer.resize();
    // if( !this.pinoGL.canvas ) return;
    emmausModel.then( () => {

    store.subscribe( this.stateChanged );
    this.stateChanged();
    })

    this.controls = new MapControls( this.pinoGL.camera, this.pinoGL );
    this.controls.enableRotate = false;
    this.controls.enableZoom = false;
    this._cameraman = new Cameraman( this.pinoGL.camera );
    this._cameraman.addEventListener( 'arrived', event => {
      this._moving = false;
    })

    // https://discourse.threejs.org/t/how-camera-fit-object-to-left-half-side-of-the-screen-width-and-height/7584/6
    // this.pinoGL.camera.setViewOffset(window.innerWidth * 1, window.innerHeight, window.innerWidth * 0.25, 0, window.innerWidth, window.innerHeight)

    // this.controls.enableZoom = true;
    // // this.controls.enablePan = true;
    // this.controls.touches = {
    //   // ONE: TOUCH.DOLLY_ROTATE,
    //   ONE: TOUCH.DOLLY_PAN,
    //   TWO: TOUCH.DOLLY_ROTATE,
    // },
    // this.controls.mouseButtons = {
    //   LEFT: MOUSE.PAN,
    //   MIDDLE: MOUSE.DOLLY,
    //   RIGHT: MOUSE.ROTATE,
    // }
    // this.controls.minDistance = 2;
    // this.controls.maxDistance = 3;
    // this.controls.minPolarAngle = MathUtils.degToRad( 90 - 20 ); // radians
    // this.controls.maxPolarAngle = MathUtils.degToRad( 90 + 20 ); // radians
    this.controls.enableDamping = true;
    // this.controls.enableZoom = false;
    // this.controls.target.copy( new Vector3(2, 1.3, 2))
    // this.controls.enabled = true;

    const ambient = new AmbientLight( 0xffffff, .6 );
    this.pinoGL.scene.add( ambient );

    const links = new Links();
    this.pinoGL.scene.add( links );

    this.pinoGL.scene.add( this.emmaus );

    const directional = new DirectionalLight( 0xf1faff, 0.6 );
    directional.position.set( -10, 20, 20 );
    directional.target.position.set( -.2, 0, 1.5 );

    directional.target.updateMatrixWorld();

    directional.shadow.camera.far = 50;
    directional.shadow.camera.near = 20;
    directional.shadow.bias = -0.002;
    directional.shadow.camera.left = -10;
    directional.shadow.camera.right= 10;
    directional.shadow.camera.top = 10;

    // directional.shadow.camera.near = 1;
    // const directionCameraHelper = new CameraHelper( directional.shadow.camera );
    // this.pinoGL.scene.add( directionCameraHelper );
    directional.castShadow = true;
    directional.shadow.mapSize.width = 512;
    directional.shadow.mapSize.height = 512;
    // const multiplier = 7;
    // directional.shadow.camera.left = -5*multiplier;
    // directional.shadow.camera.right = 5*multiplier;
    // directional.shadow.camera.left = -5*multiplier;
    // directional.shadow.camera.right = 5*multiplier;
    // directional.shadow.camera.bottom = -5*multiplier;
    // directional.shadow.camera.bottom = -5*multiplier;
    // window.directional = directional;
    // const cube = new Mesh(
    //   new BoxBufferGeometry(),
    //   new CrossHatching(),
    // );
    // cube.material.uniforms.repeat.value.set( 1, 1 );
    // this.pinoGL.scene.add( cube );
    this.pinoGL.scene.add( directional );
    // const helper = new DirectionalLightHelper( directional, 1, '#000000' );
    // this.pinoGL.scene.add( helper );

    this.pinoGL.camera.position.set( 0, 2.6, 3 );

    this.group.name = 'exhibits';
    this.pinoGL.scene.add( this.group );

    this.tick();

    window.addEventListener( 'resize', this._onResize );
    document.body.addEventListener("click", this.onClick, true);
  }

  onClick = ( event: MouseEvent ) => {

    if( !location.pathname.includes( '/explore' ) ) return;
    const raycaster = new Raycaster();

    var mouse = new Vector2();
    mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
    raycaster.setFromCamera(mouse, this.pinoGL.camera);
    var intersects = raycaster.intersectObjects(this.pinoGL.scene.children, true); //array
     if (intersects.length > 0) {
       const canvas = intersects.find( intersect => intersect.object.name.includes( 'canvas' ) );
       const mailbox = intersects.find( intersect => intersect.object.name.includes( 'mailbox' ) );
       const pino = intersects.find( intersect => intersect.object.name.includes( 'pino' ) );
       const toilet = intersects.find( intersect => intersect.object.name.includes( 'toilet' ) );
       const safe = intersects.find( intersect => intersect.object.name.includes( 'safe' ) );
       const garbage = intersects.find( intersect => intersect.object.name.includes( 'garbage' ) );
       if( mailbox ) {
         store.dispatch( push( '/contact' ) );
       } else if( garbage ) {

         store.dispatch( push( '/install' ) );
       } else if( pino ) {

         store.dispatch( push( '/about' ) );
       } else if( toilet ) {
         store.dispatch( push( '/imprint' ) );

       } else if( safe ) {
         store.dispatch( push( '/privacy' ) );


      //  const canvas = intersects.find( intersect => intersect.object.name.includes( 'canvas' ) );
      //  const objects = intersects.filter( intersect => intersect.object.type !== 'Points' );
      //  const names = objects.map( object => object.object.name )
      // const exhibit = canvas?.object.parent.parent as Exhibit;
      //  console.log( canvas?.object.parent?.parent.name );
       } else if( canvas?.object.parent?.parent ) {

        const exhibit = canvas.object.parent.parent as Exhibit;
        store.dispatch( push( `/gallery/${ exhibit.index }`))
       }
      //  console.log( canvas?.object.parent.parent);
       this.dispatchEvent( new CustomEvent( 'canvas') )
     }
  }

  _onResize = ( event: Event ) => {

    const target = event.target as Window;
    this._width = target.innerWidth;
    this._height = target.innerHeight;
  }

  tick = () => {

    requestAnimationFrame( this.tick );
    if( !this.pinoGL.renderer ) return;
    if( this._explore && !this._moving ) {

      this.controls.update();
      this.controls.enabled = true;
    } else {
      this.controls.enabled = false;
      this.controls.target = this._cameraman.target.position;
      if(this._cameraman ) this._cameraman.tick();

    }

    this.pinoGL.renderer.render( this.pinoGL.scene, this.pinoGL.camera );
    this.group.children.forEach( ( child ) => {
      const model = child as Model;
      model.tick()
    } );
    this.emmaus.tick();
  }

  render() {
    return html`
      <pino-gl
        .width="${ this._width }"
        .height="${ this._height }"
      ></pino-gl>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'pino-emmaus': PinoEmmaus,
  }
}
