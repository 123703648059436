import { createListenerMiddleware } from "@reduxjs/toolkit";
import { Color } from "three";
import { defaultMaterial } from "../../materials";
import { colorSchemeUpdated, } from "../slices/app";
import { RootState } from "../store";

const colorSchemeListenerMiddleware = createListenerMiddleware();

colorSchemeListenerMiddleware.startListening({

  actionCreator: colorSchemeUpdated,
  effect: async ( _, listenerApi) => {

    const state = listenerApi.getState() as RootState;
    defaultMaterial.color = new Color( state.app.colorScheme === 'light' ? 0xffffff : 'hsl(207, 50%, 2%)');

  }
})

export default colorSchemeListenerMiddleware.middleware;
