import './pino-app';
import { init, currentLocaleUpdated, userLocaleUpdated, } from './store/slices/app';
import store, { RootState } from './store/store';
import { fetchUser } from './store/slices/user';
import { fetchExhibits } from './store/slices/exhibits';

import { fetchCameraPositions } from './store/slices/emmaus';

store.dispatch( init() );
store.dispatch( fetchCameraPositions() );

const state = store.getState() as RootState;

if( state.auth.token ) store.dispatch( fetchUser() );
store.dispatch( fetchExhibits() );

import './index.css';

// const clock = new Clock();
// const tween = new TWEEN.Tween()
// tween.update()

// const tick = () => {
//   requestAnimationFrame( tick )
//   TWEEN.update( clock.getElapsedTime() );


// }

import TWEEN, { update } from '@tweenjs/tween.js';

const tick = () => {
  TWEEN.update();
  requestAnimationFrame( tick );
}

if( state.app.userLocale ) {
  store.dispatch( currentLocaleUpdated( state.app.userLocale ) );
} else if( navigator.language.includes( 'it' ) ) {
  store.dispatch( currentLocaleUpdated( 'it-IT' ) );
} else if( navigator.language.includes( 'de' ) ) {
  store.dispatch( currentLocaleUpdated( 'de-DE' ) );
} else if( navigator.language.includes( 'nl' ) ) {
  store.dispatch( currentLocaleUpdated( 'nl-NL' ) );
}

tick();

// const div = document.createElement( 'div' );
// div.style.background = 'red';
// div.style.width = '500px';
// div.style.height = '500px';

// document.body.appendChild( div );

// const coords = { x: 0, y: 0, }
// const tween = new Tween( coords )
// .to({ x: 300, y: 200 }, 10000 )
// .onUpdate( () => {
//   div.style.transform = `translate( ${ coords.x }px, ${ coords.y }px )`
// })
// .start();

// setInterval( () => {
//   tween.update();

// }, 200 )
