import { createListenerMiddleware } from "@reduxjs/toolkit";
import { cameraPositionsFetched, currentCameraPositionUpdated } from "../slices/emmaus";
import { push, routerUpdated, silentPush } from "../slices/router";
import store, { RootState } from "../store";

const routerListenerMiddleware = createListenerMiddleware();

routerListenerMiddleware.startListening({
  actionCreator: silentPush,
  effect: async (action, listenerApi) => {
    window.history.pushState( {}, '', action.payload );
  }
} );

routerListenerMiddleware.startListening({
  actionCreator: push,
  effect: async (action, listenerApi) => {
    window.history.pushState( {}, '', action.payload );
    window.dispatchEvent( new PopStateEvent( 'popstate' ) );
  }
} );

window.addEventListener( 'vaadin-router-location-changed', ( event ) => {
  const path = event.detail.location.route?.path || '';
  store.dispatch( currentCameraPositionUpdated( path ) );
  store.dispatch( routerUpdated({
    hash: event.detail.location.hash,
    params: event.detail.location.params,
    pathname: event.detail.location.pathname,
    search: event.detail.location.search,
  }));
  // listenerApi.dispatch( push( { pathname: location.pathname }))
})

routerListenerMiddleware.startListening({
  actionCreator: cameraPositionsFetched,
  effect: async (action, listenerApi) => {

    const state = listenerApi.getState() as RootState;


    // listenerApi.dispatch( push( { pathname: location.pathname }))
    // listenerApi.dispatch( currentCameraPositionUpdated( location.pathname ) );
  }
} );


export default routerListenerMiddleware.middleware;

