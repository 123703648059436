import { html } from "lit";

export default html`
<style>
  :host {
    --pino-main-contrast: black;
    --pino-main: hsl(0, 0%, 100%);
    --pino-main-dark: hsl(210, 9%, 91%);

    --pino-primary: hsl(222, 37%, 26%);

    /* Card */
    --pino-card-background: var( --pino-main );

    /* Header */
    --pino-header-background: var( --pino-main );

    /* Rough */
    --pino-rough: var( --pino-main-contrast );
  }
</style>
`;
