import {
  Object3D,
  PerspectiveCamera,
  Vector3,
  AudioListener,
} from "three";
import { Model } from "./model";

import { Tween, Easing, } from '@tweenjs/tween.js';
import { CameraPosition, Offset } from "../store/slices/emmaus";
import {
  // currentListener
} from "../resources";

export class Cameraman extends Model {

  private _targetTween!: Tween<Vector3>;
  private _cameraTween!: Tween<Vector3>;
  private _offsetTween!: Tween<Offset>;

  private _cameraPosition: CameraPosition | null = null;

  public offset: Offset = { value: 0, name: 'center', };

  public camera!: PerspectiveCamera;
  public target = new Object3D();
  public listener = new AudioListener();

  private _isMobile = false;
  get isMobile() {
    return this._isMobile;
  }
  set isMobile( isMobile ) {
    this._isMobile = isMobile;
    if( this._cameraPosition ) this._goToCameraPosition( this._cameraPosition )
  }

  constructor( camera: PerspectiveCamera ) {
    super();

    this.camera = camera;
    // this.camera.add( currentListener );
    this._targetTween = new Tween( this.target.position );
    this._cameraTween = new Tween( this.camera.position );

  }

  private _goToCameraPosition( cameraPosition: CameraPosition ) {

    // this.offset = cameraPosition.offset;
    this._cameraPosition = cameraPosition;
    this.camera.setViewOffset(
      window.innerWidth * 1,
      window.innerHeight,
      window.innerWidth * ( this._isMobile ? 0 : cameraPosition.offset.value ),
      0,
      window.innerWidth,
      window.innerHeight
    );
    this.target.position.set(
      cameraPosition.target.x,
      cameraPosition.target.y,
      cameraPosition.target.z,
    );
    this.camera.position.set(
      cameraPosition.camera.x,
      cameraPosition.camera.y,
      cameraPosition.camera.z,
    )
  }

  playCameraPosition( cameraPosition: CameraPosition ) {

    this._cameraPosition = cameraPosition;
    // https://discourse.threejs.org/t/how-camera-fit-object-to-left-half-side-of-the-screen-width-and-height/7584/6
    // this.camera.setViewOffset(
    //   window.innerWidth * 1,
    //   window.innerHeight,
    //   window.innerWidth * 0.25,
    //   0,
    //   window.innerWidth,
    //   window.innerHeight
    // );
    // this.offset = cameraPosition.offset;
    this._offsetTween = new Tween( this.offset )
    .to( cameraPosition.offset, 2000 )
    .easing( Easing.Cubic.InOut )
    .onUpdate( offset => {
      this.camera.setViewOffset(
        window.innerWidth * 1,
        window.innerHeight,
        window.innerWidth * ( this._isMobile ? 0 : offset.value ),
        0,
        window.innerWidth,
        window.innerHeight
      );
    } )
    .start();
    this._targetTween = new Tween( this.target.position )
    .to( new Vector3( cameraPosition.target.x, cameraPosition.target.y, cameraPosition.target.z ), 2000 )
    .easing( Easing.Quintic.Out )
    .onUpdate( position => {
      // this.target.position.set( position.x, position.y, position.z );
    })
    .start();

    this._cameraTween = new Tween( this.camera.position )
    .to( new Vector3( cameraPosition.camera.x, cameraPosition.camera.y, cameraPosition.camera.z ), 2000 )
    // .easing( Easing.Cubic.InOut )
    .easing( Easing.Cubic.InOut )
    // .delay( 500 )
    .onUpdate( position => {


      // this.camera.position.set( position.x, position.y, position.z );
      // this.camera.lookAt( this.target.position );
    })
    .onComplete( () => {
      this.dispatchEvent( { type: 'arrived', message: cameraPosition  })
    })
    .start();


  }

  tick() {

    this.camera.lookAt( this.target.position );
    // this._targetTween.update();
    // this._cameraTween.update();

  }

}
