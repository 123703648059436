import { Color, MeshBasicMaterial, MeshStandardMaterial } from "three";

const saturation = 30;
const lightness = 80;

const createPastelMaterials = ( hue: number ) => {
  return new MeshStandardMaterial( {
    color: new Color( `hsl( ${ hue }, ${ saturation }%, ${ lightness }% )`),
    metalness: 0,
  } );
}

const pastelMaterials = [
  createPastelMaterials( 0 ),
  createPastelMaterials( 30 ),
  createPastelMaterials( 60 ),
  createPastelMaterials( 90 ),
  createPastelMaterials( 120 ),
  createPastelMaterials( 150 ),
  createPastelMaterials( 180 ),
  createPastelMaterials( 210 ),
  createPastelMaterials( 240 ),
  createPastelMaterials( 270 ),
  createPastelMaterials( 300 ),
  createPastelMaterials( 330 ),
]

export const defaultMaterial = new MeshStandardMaterial( {
  color: 0xffffff,
  metalness: 0,
} );

export const getRandomPastelMaterial = () => {
  const randomIndex = Math.floor( Math.random() * pastelMaterials.length )
  return pastelMaterials[ randomIndex ];
}


export const getPastelMaterial = ( index: number ) => {
  if (!pastelMaterials[ index ] ) return getRandomPastelMaterial();
  return pastelMaterials[ index ];
}
