import {
  AudioLoader,
  TextureLoader,
  AudioListener,
} from "three";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const gltfLoader = new GLTFLoader();
const audioLoader = new AudioLoader();
const textureLoader = new TextureLoader();

const audioPrefix = '/audio/';
const modelPrefix = '/models';
const texturePrefix = '/textures';

// Models;

export const mailboxModel = gltfLoader.loadAsync( `${ modelPrefix }/mailbox.glb` )
  .then( gltf => gltf.scene );
export const toiletModel = gltfLoader.loadAsync( `${ modelPrefix }/toilet.glb` )
  .then( gltf => gltf.scene );
export const safeModel = gltfLoader.loadAsync( `${ modelPrefix }/safe.glb` )
  .then( gltf => gltf.scene );
export const garbageModel = gltfLoader.loadAsync( `${ modelPrefix }/garbage.glb` )
  .then( gltf => gltf.scene );

export const canvasModel = gltfLoader.loadAsync( `${ modelPrefix }/canvas.glb` )
  .then( gltf => gltf.scene );

export const emmausModel = gltfLoader.loadAsync( `${ modelPrefix }/emmaus.glb` )
  .then( gltf => gltf.scene );

export const butterflyModel = gltfLoader.loadAsync( `${ modelPrefix }/butterfly.glb` )
  .then( gltf => gltf.scene );

export const birdModel = gltfLoader.loadAsync( `${ modelPrefix }/bird.glb` )
  .then( gltf => gltf.scene );

export const birdFlyingModel = gltfLoader.loadAsync( `${ modelPrefix }/bird-flying.glb` )
  .then( gltf => gltf.scene );

export const TruckModel = gltfLoader.loadAsync( `${ modelPrefix }/truck.glb` )
  .then( gltf => gltf.scene );

export const exhibitTypesModel = new GLTFLoader().loadAsync( `${ modelPrefix }/exhibit-types.glb` )
  .then( gltf => gltf.scene );

export const pinoModel = new GLTFLoader().loadAsync( `${ modelPrefix }/pino.glb` )
  .then( gltf => {
   return gltf;
   } );

// Paths

export const truckPath = gltfLoader.loadAsync( `${ modelPrefix }/truck-path.glb` )
  .then( gltf => gltf.scene );

export const birdPaths = gltfLoader.loadAsync( `${ modelPrefix }/bird-paths.glb` )
  .then( gltf => gltf.scene );

// Positions

export const linkPositions = gltfLoader.loadAsync( `${ modelPrefix }/links.glb` )
export const pinoPosition = gltfLoader.loadAsync( `${ modelPrefix }/pino-position.glb` )
  .then( gltf => gltf.scene );

export const cameraPositions = gltfLoader.loadAsync( `${ modelPrefix }/cameras.glb` )
  .then( gltf => gltf.scene );

// Textures

export const dustTexture = textureLoader.loadAsync( `${ texturePrefix }/dust-64.png` )
export const faceTexture = textureLoader.loadAsync( `${ texturePrefix }/face.png` )

// Audio
//
// export const currentListener = new AudioListener();

// export const chirp1 = audioLoader.loadAsync( `${ audioPrefix }chirp-1.mp3` );
// export const chirp2 = audioLoader.loadAsync( `${ audioPrefix }chirp-2.mp3` );
// export const chirp3 = audioLoader.loadAsync( `${ audioPrefix }chirp-3.mp3` );
// export const chirp4 = audioLoader.loadAsync( `${ audioPrefix }chirp-4.mp3` );
// export const chirp5 = audioLoader.loadAsync( `${ audioPrefix }chirp-5.mp3` );
