import { html, css, PropertyValueMap, } from 'lit'
import { customElement, property, query, state, } from 'lit/decorators.js'
import { ComponentElement } from '../elements/component-element'
import rough from 'roughjs';

import TWEEN, { Tween, Easing, } from '@tweenjs/tween.js';
import store, { RootState } from '../store/store';
import { RoughCanvas } from 'roughjs/bin/canvas';

/**
 * An example element.
 *
 * @slot - This element has a slot
 * @csspart button - The button
 */

@customElement('pino-rough')
export class PinoRough extends ComponentElement {

  @query( 'canvas' ) _canvas!: HTMLCanvasElement;

  @property( { type: Number } ) delay = 0;
  @property( { type: String } ) source = '';
  @property( { type: Boolean } ) present = true;
  @property( { type: Boolean, reflect: true, attribute: 'fade-out' } ) fadeOut = false;

  @property({ type: Boolean, reflect: true, }) dark = false;

  private _color = 'black';
  private _tweenValue = { value: 1, lastValue: 0, };
  private _roughCanvas!: RoughCanvas;
  private _context!: CanvasRenderingContext2D;
  private _svg!: SVGSVGElement;

  static styles = [
  super.styles,
  css`
    :host {
    }
  ` ]

  render() {
    return html`
      <canvas width="0" height="0"></canvas>
    `
  }

  private _updateColor() {
    this._color = this.dark ? 'white' : 'black';
      // this._color = getComputedStyle(this).getPropertyValue( '--pino-rough' )
    // setTimeout( () => {
    // }, 100 )
  }

  protected firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
  }

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if( _changedProperties.has( 'dark' ) && this._context ) {
      this.onUpdate( true )
    }
    if( _changedProperties.has( 'source' ) && this.source ) {
      fetch ( this.source )
      .then( response => response.text() )
      .then( data => {
        const svg = new DOMParser()
          .parseFromString( data, 'image/svg+xml' )
          .querySelector( 'svg' ) as SVGSVGElement;

          if( !svg ) throw Error( 'No element found!' );
          this._svg = svg;
          this.animateCanvas();
      } );
    }
  }

  private _stepsEasing = ( step: number, steps: number = 10 ) => {
    return Math.floor( step * steps) / steps;
  }

  private _stepsEasingMiddle = ( step: number, steps: number = 30 ) => {
    return Math.floor( step * steps) / steps;
  }

  onUpdate = ( force = false ) => {

    this._updateColor();
    if ( this._tweenValue.lastValue === this._tweenValue.value && !force ) return;
    this._tweenValue.lastValue = this._tweenValue.value;
    this._context.clearRect( 0, 0, this._canvas.width, this._canvas.height );

    [ ...this._svg.children ].forEach( child => {
        if( child.classList.contains( 'zigzag' ) ) {

          if( child.tagName !== 'path' ) return;
          const path = child as SVGPathElement;
          const length = path.getTotalLength()

          const d = child.getAttributeNS( null, 'd' );
          if( !d ) return;

          this._roughCanvas.path( d, { stroke: this.dark ? 'white' : 'black', fill: this.dark ? 'white' : 'black', fillStyle: 'zigzag',
          strokeLineDash:[ length ],
          strokeLineDashOffset: (length * this._tweenValue.value),
    });
        } else if( child.classList.contains( 'white' ) ) {
          const d = child.getAttributeNS( null, 'd');
          if( !d ) return;
          this._roughCanvas.path( d, { fill: 'white', fillStyle: 'solid' });
        } else if( child.classList.contains( 'solid') ) {
          const d = child.getAttributeNS( null, 'd');
          if( !d ) return;
          this._roughCanvas.path( d, { stroke: this.dark ? 'white' : 'black', fill: this.dark ? 'white' : 'black', fillStyle: 'solid' });

        } else {

          if( child.tagName !== 'path' ) return;
          const path = child as SVGPathElement;
          const length = path.getTotalLength()

          const d = path.getAttributeNS( null, 'd');
          if( !d ) return;
          this._roughCanvas.path( d, { fill: 'transparent', stroke: this._color, fillStyle: 'solid',
          strokeLineDash:[ length ],
          // dashGap: length,
          strokeLineDashOffset: (length * this._tweenValue.value),
          // dashOffset: length -70,
          // disableMultiStroke: true,
          // preserveVertices: true,
        });

        }
    })

  }

  animateCanvas() {


    this._roughCanvas = rough.canvas( this._canvas );
    this._canvas.width = this._svg.viewBox.baseVal.width;
    this._canvas.height = this._svg.viewBox.baseVal.height;
    this._context = this._canvas.getContext( '2d' ) as CanvasRenderingContext2D;

    let lastValue = 0;

    const start = new Tween( this._tweenValue ).to( { value: 0 }, 1500 )
    .easing( this._stepsEasing )
    .delay( this.delay )
    .onUpdate( () => this.onUpdate() )

    const middle = new Tween( this._tweenValue ).to( { value: 0.01 }, 4500 )
    .easing( this._stepsEasingMiddle )
    .onUpdate( () => this.onUpdate() )

    const end = new Tween( this._tweenValue ).to( { value: 1 }, 1500 )
    .easing( this._stepsEasing )
    .onUpdate( () => this.onUpdate() )
    .onComplete( () => {
      this._context.clearRect( 0, 0, this._canvas.width, this._canvas.height );
    })

    if( this.present ) start.chain( middle )
    if( this.fadeOut ) middle.chain( end );

    start.start()
    this.paint()
  }


  paint = () => {
    requestAnimationFrame( this.paint )
    // TWEEN.update();

  }
}


declare global {
  interface HTMLElementTagNameMap {
    'pino-rough': PinoRough,
  }
}
