import { createListenerMiddleware } from "@reduxjs/toolkit";
import { init } from "../slices/app";
import { tokenUpdated } from "../slices/auth";
import { DefaultLoadingManager } from 'three';
import { progressUpdated } from "../slices/loader";
import store from "../store";

const loadingListenerMiddleware = createListenerMiddleware();

DefaultLoadingManager.onProgress = ( url, loaded, total ) => {
  store.dispatch( progressUpdated( { loaded, total }) );
}

export default loadingListenerMiddleware.middleware;
