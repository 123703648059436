import {
  Color,
  Group,
  Mesh,
  MeshStandardMaterial,
  Object3D,
  Vector3,
} from "three";

import { Model } from "./model";
import { Dust } from "./dust";
import { Tween, Easing, } from "@tweenjs/tween.js";
import { birdModel, birdPaths } from "../resources";
import { Bird } from "./bird";

interface Options {
  count?: number,
}

export interface Area {
  name: string,
  positions: Vector3[],
}

export class Flock extends Model {

  static model = birdModel;
  private _birds = new Group();
  private _tween = new Tween( { value: 0, } );
  private _count = 0;

  private _material = new MeshStandardMaterial({
    color: 0xffffff,
  })

  private _leftPositions: Object3D[] = [];
  private _rightPositions: Object3D[] = [];
  private _areas: Area[] = [];

  private _dust = new Dust();

  private _escapePoint = new Vector3();

  private _color = new Color();
  get color() { return `${ this._color.getHex() }`; }
  set color( color: string ) {
    this._color = new Color( color );
    this._material.color = this._color;
  }

  constructor( options: Options = {} ) {
    super();

    const {
      count= 5,
    } = options;


    this._count = count;
    this.add( this._birds );

    Promise.all( [ birdPaths, birdModel ] )
      .then( ( [ paths ]) => {
        this._rightPositions = paths.children
          .filter( child => child.name.includes( 'bird-right' ) )

        this._leftPositions = paths.children
          .filter( child => child.name.includes( 'bird-left' ) )

        this._areas = paths.children
          .filter( child => child.name.includes( 'bird-walk' ) )
          .map( object3d => {
            const mesh = object3d as Mesh;

            const area: Area = {
              name: mesh.name,
              positions: [],
            }
            const position = mesh.geometry.attributes.position;
            for( let index = 0; index < position.count; index++ ) {
              area.positions.push( new Vector3(
                position.getX( index ) + mesh.position.x,
                position.getY( index ) + mesh.position.y,
                position.getZ( index) + mesh.position.z,

              ))
            }

            return area;
          })

          // this._areas = [{
          //   name: 'affe',
          //   positions: [
          //     this._areas[0].positions[0],
          //     this._areas[0].positions[1],
          //     this._areas[0].positions[2],
          //     this._areas[0].positions[3],
          //     this._areas[0].positions[4],
          //     this._areas[0].positions[5],
          //     this._areas[0].positions[6],
          //     this._areas[0].positions[7],
          //     this._areas[0].positions[8],
          //   ]
          // }
        // ]

          // ]
        // this._areas = [ this._areas[0] ];

        // for( let index = 0; index < count; index++ ) {
        //   this._birds.add( new Bird() );
        // }
        this.updateEscapePoint();
        this.addBird();

      })

  }

  updateEscapePoint() {
    this._escapePoint = this.getRandomPoint();
  }

  getRandomPoint() {
    const positions = [ ...this._rightPositions, ...this._leftPositions ];
    const randomIndex = Math.floor( Math.random() * positions.length )
    return positions[ randomIndex ].position.clone();
  }

  getRandomArea() {
    const randomIndex = Math.floor( Math.random() * this._areas.length )
    return this._areas[ randomIndex ];
  }

  private _getFreeSlot( bird: Bird, tryouts = 0 ): Vector3 {

    if( tryouts > 10 ) throw new Error( 'Tried enough!' );

    const birdsInArea = this._birds.children.filter( child => {
      const birdInPossibleArea = child as Bird;
      return bird.currentArea === birdInPossibleArea.currentArea && bird !== birdInPossibleArea;
    } ) as Bird[];

    const randomIndex = Math.floor( Math.random() * bird.currentArea.positions.length )
    const possiblePosition = bird.currentArea.positions[ randomIndex ];

    const occupied = birdsInArea.find( birdInArea => {
      return possiblePosition.x === birdInArea.currentPosition.x &&
        possiblePosition.y === birdInArea.currentPosition.y &&
        possiblePosition.z === birdInArea.currentPosition.z;

    } )

    if( occupied ) {
      // console.log('occupando!', tryouts )
      return this._getFreeSlot( bird, tryouts + 1 );
    } else {
      return possiblePosition;
    }
  }

  addBird() {
    if( this._birds.children.length >= this._count ) return;

    const area = this.getRandomArea()
    const bird = new Bird( area, this._escapePoint );

    bird.currentPosition = this._getFreeSlot( bird );
    bird.land( this.getRandomPoint() );

    const startWalking = () => {

      setTimeout( () => {
        bird.currentPosition = this._getFreeSlot( bird );
        bird.walk();
      }, Math.random() * 1000 + 2000 )
    }

    bird.addEventListener( 'landed', startWalking );

    bird.addEventListener( 'walked', startWalking );

    this._birds.add( bird );
    setTimeout( () => {
      this.addBird();
    }, Math.random() * 100 + 100 )
  }

  tick() {
    this._birds.children.forEach( child => {
      const bird = child as Bird;
      bird.tick();
    } )
  }

}
