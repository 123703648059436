import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface AuthState {
  token: string,
}

const initialState: AuthState = {
  token: '',
};

interface GithubUser {
  login: string,
  id: number,
  avatar_url: string,

}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    tokenUpdated: (state, action: PayloadAction<string>) => {
      // mutate the state all you want with immer
      state.token = action.payload;
    },
  },
} );

export const fetchToken = createAsyncThunk(
  'fetch/token',
  async ( code: string, thunkAPI ) => {

    return fetch('/.netlify/functions/authorize', {
      method: 'POST',
      body: JSON.stringify( { code, }),
      }).then( response => {

        if( !response.ok ) throw Error( 'No Token.' );
        return response.json();

      }).then( data => {
        thunkAPI.dispatch( tokenUpdated( data.payload ) );
        return data;
      })
      .catch( error => {
      })


  }
)

const { actions, reducer } = authSlice;

export const { tokenUpdated } = actions
export default reducer;
