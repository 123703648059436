import { property } from "lit/decorators";
import { Group, Mesh, Scene } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";


export abstract class Model extends Group {

  static modelPath = '/';

  abstract tick( elapsedTime?: number ): void;

}
