import { createListenerMiddleware } from "@reduxjs/toolkit";
import { UserLocale, init, makingOfEnabled, userLocaleUpdated } from "../slices/app";
import store from "../store";

const storageListenerMiddleware = createListenerMiddleware();

// const tokenKey = 'pino:token';

// storageListenerMiddleware.startListening({
//   actionCreator: init,
//   effect: async ( _, listenerApi) => {

//     const token = localStorage.getItem( tokenKey );

//     if( token ) {
//       listenerApi.dispatch( tokenUpdated( token ) );
//     }

//   }
// })

const colorSchemeKey = 'pino:color-scheme';
localStorage.setItem( colorSchemeKey, 'light' );
const userLocaleKey = 'pino:user-locale';
const makingOfKey = 'pino:making-of';

storageListenerMiddleware.startListening({
  actionCreator: init,
  effect: async ( _, listenerApi) => {

    const locale = localStorage.getItem( userLocaleKey ) as UserLocale;

    if( locale ) {
      listenerApi.dispatch( userLocaleUpdated( locale ) );
    }

    const makingOf = localStorage.getItem( makingOfKey );

    if( makingOf ) {
      store.dispatch( makingOfEnabled() );
    }

  }
})

storageListenerMiddleware.startListening({
  actionCreator: makingOfEnabled,
  effect: async ( action, listenerApi) => {

    localStorage.setItem( makingOfKey, 'enabled' );

  }
})

storageListenerMiddleware.startListening({
  actionCreator: userLocaleUpdated,
  effect: async ( action, listenerApi) => {

    localStorage.setItem( userLocaleKey, action.payload );

  }
})


// storageListenerMiddleware.startListening({
//   actionCreator: init,
//   effect: async ( _, listenerApi) => {

//     const mediaScheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
//     const colorScheme = localStorage.getItem( colorSchemeKey ) as ColorScheme || mediaScheme;

//     listenerApi.dispatch( colorSchemeUpdated( colorScheme ) );

//     window.matchMedia( '(prefers-color-scheme: dark)' ).addEventListener( 'change', event => {
//       const colorScheme = event.matches ? 'dark' : 'light';
//       store.dispatch( colorSchemeUpdated( colorScheme ) );
//     });
//   }
// } )

// storageListenerMiddleware.startListening({

//   actionCreator: colorSchemeUpdated,
//   effect: async ( _, listenerApi) => {

//     const state = listenerApi.getState() as RootState;

//     localStorage.setItem( colorSchemeKey, state.app.colorScheme );

//   }
// })

export default storageListenerMiddleware.middleware;
